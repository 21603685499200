import xagiTitleImage from '../../../assets/images/animations/min/xagi-min.jpg'
//
import hagi_vagi1 from '../../../assets/images/gallery/hagi_vagi/hagi_vagi1.jpg'
//
import hagi_vagi_v1 from '../../../assets/images/gallery/hagi_vagi/hagi_vagi_v1.mp4'
//
import {iContentPathItem} from "../types/type";

export const XagiContent: iContentPathItem = {
    titleImage: xagiTitleImage,
    titleVideo: '',
    images: [
        {src: hagi_vagi1},
    ],
    videos: [
        {src: hagi_vagi_v1},
    ]
}