
import pony1 from '../../../assets/images/gallery/pony/pony1.jpg'
import pony2 from '../../../assets/images/gallery/pony/pony2.jpg'
import pony3 from '../../../assets/images/gallery/pony/pony3.jpg'
import pony_v1 from '../../../assets/images/gallery/pony/pony_v1.mp4'
import pony_v2 from '../../../assets/images/gallery/pony/pony_v2.mp4'
import {iContentPathItem} from "../types/type";
import unicornImage from '../../../assets/images/animations/min/unicorn-min.jpg'
export const UnicornContent: iContentPathItem = {
    titleImage: unicornImage,
    titleVideo: '',
    images: [
        {src: pony1},
        {src: pony2},
        {src: pony3},
    ],
    videos: [
        {src: pony_v1},
        {src: pony_v2},
    ]
}