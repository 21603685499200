import sonik1 from '../../../assets/images/gallery/sonik/sonik1.jpg'
import sonik_v1 from '../../../assets/images/gallery/sonik/sonik_v1.mp4'
import {iContentPathItem} from "../types/type";

export const SonicContent: iContentPathItem = {
    titleImage: sonik1,
    titleVideo: '',
    images: [
        {src: sonik1},
    ],
    videos: [
        {src: sonik_v1},
    ]
}