import React, {useEffect, useState} from 'react';
import {scrollToTop} from '../logic/logic';

interface ScrollOnTopProps {
    children?: string | JSX.Element | JSX.Element[]
}

export const ScrollOnTop: React.FC<ScrollOnTopProps> = () => {
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    //
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    //
    return (
        <>
             <span
                 onClick={() => {
                     scrollToTop()
                 }}
                 className={(scrollPosition >= 300 ? '' : ' hidden ') + ' hover:scale-105 hover:cursor-pointer fixed bottom-2 lg:bottom-5 right-2 lg:right-5 p-2 bg-yellow-400 flex items-center justify-center rounded-lg z-50'}
             >
           <svg className={'w-8 h-8'} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path
               d="M450 896V370L202 618l-42-42 320-320 320 320-42 42-248-248v526h-60Z"
           /></svg>
        </span>
        </>
    );
};
