import React from 'react';
import {ComponentLayout} from "../../../shared/section-layout/UI/component-layout";
import {SimpleTitle, titleAlign} from "../../../shared/titles/simpleTitle";

interface AboutProps {
    children?: string | JSX.Element | JSX.Element[]
    data: any
}

export const About: React.FC<AboutProps> = props => {
    return (
        <>
            <ComponentLayout>
                <SimpleTitle
                    title={' Детские праздники в Республике Беларусь'}
                    description={' '}
                    titleAlign={titleAlign.center}
                />
                <div className="space-y-6 text-center lg:text-start">
                    <p> Аниматоры Минск </p>
                    <p> “Эвент Точка” - большая и современная компания, которая не боится экспериментировать и делать
                        программы под заказа. Помимо стандартных программ, вы можете увидеть нас различные виды
                        сценариев, адаптаций под ваши желания и индивидуальные программы. </p>
                    <p> Наша компания не только проводит анимационные программы, но и делает квесты, спектакли, игровые
                        программы. С нами сотрудничают различные площадки, декораторы, музыканты, вокалисты, актёры,
                        диджеи, швеи и многие другие, что позволяет нам делать очень многое. </p>
                    <p> Если вы желаете пообщаться лично, чтобы обсудить все детали программы, мы можем выехать к вам в
                        любое для вас удобное место.</p>
                    <p> Наши актеры-аниматоры постоянно проходят обучение и совершенствуют свои навыки в работе с
                        детьми, взрослыми, а также принимают активное участие в создании программ и репетициях.</p>
                    <p> Мы работаем не только в Минске, но и по всей Беларуси.</p>
                    <p> Мы работаем с детьми и подростками от 2 до 18 лет, а также активно вовлекаем взрослых.</p>
                    <p> Наша костюмерная база, база программ, шоу и мастер классов постоянно расширяется и появляется
                        что-то новое. </p>
                    <p> Во время шоу где есть прямой контакт с огнем, сухим льдом, азотом и другими веществами, строго
                        соблюдается техника безопасности чтобы не допускать неприятных и опасных ситуаций, каждый наш
                        актер ее знает и проинструктирован. </p>
                    <p> Адаптация программ происходит под любое пространство, будь то большой банкетный зал или
                        маленькая комната, мы сможем провести ваш праздник где угодно.</p>
                    <p> Оформление заказа лучше делать заранее, особенно если вы хотите индивидуальную программу, так
                        как ее подготовка требует времени. </p>
                    <p> Длительность мероприятия зависит от вашего желания и предпочтения. Это может быть как 30 минут
                        так и 10 часов. </p>
                    <p> На празднике может присутствовать неограниченное количество человек, от этого будет зависеть
                        стоимость самой программы, чем больше человек, тем больше актеров задействовано, соответственно
                        цена выше. </p>
                    <p> При создании костюмов и реквизита, мы используем только качественные материалы, которые не
                        вызывают аллергию и раздражение, они абсолютно безопасны для детей и взрослых. </p>
                    <p className='flex md:flex-row flex-col  justify-between'>
                        <span>  Аниматоры Минск </span>
                        <span>  Аниматоры Брест </span>
                        <span>  Аниматоры Витебск </span>
                        <span>  Аниматоры Городно </span>
                        <span>  Аниматоры Могилев </span>
                        <span>  Аниматоры Гомель </span> 
                        <span>  Аниматоры Минская область </span> 
                    </p>
                </div>
            </ComponentLayout>
        </>
    );
};