import React, {useState} from 'react';
import {ComponentLayout} from "../../../shared/section-layout/UI/component-layout";
import {SimpleTitle, titleAlign} from "../../../shared/titles/simpleTitle";
import {StepsItem} from "./steps-item";

import bgImage from "../../../assets/images/bg/SL_050721_42770_15.jpg";

interface StepsProps {
    children?: string | JSX.Element | JSX.Element[]
    data: any
}

interface iStepsList {
    title: string
    image: string
}

const stepsList: iStepsList[] = [
    {title: 'Ознакомление', image: ''},
    {title: 'Консультация', image: ''},
    {title: 'Составление договора', image: ''},
    {title: 'Проведения мероприятия', image: ''},
    {title: 'Оплата', image: ''},
]

export const Steps: React.FC<StepsProps> = () => {
    const [steps] = useState<iStepsList[]>(stepsList);
    return (
        <>
            <ComponentLayout bgImage={bgImage}>
                <SimpleTitle
                    title={'Этапы работы'}
                    description={' '}
                    titleAlign={titleAlign.center}
                />
                <ul className={'flex flex-col lg:flex-row lg:justify-between w-full justify-center gap-12 lg:gap-0'}>
                    {steps.map((el, index) => {
                        return (
                            <StepsItem key={index} index={index} title={el.title}/>
                        )
                    })}
                </ul>
            </ComponentLayout>
        </>
    );
};