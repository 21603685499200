import akvagrim1 from '../../../assets/images/gallery/__akvagrim/akvagrim1.jpg'
import akvagrim2 from '../../../assets/images/gallery/__akvagrim/akvagrim2.jpg'
import akvagrim3 from '../../../assets/images/gallery/__akvagrim/akvagrim3.jpg'
import akvagrim4 from '../../../assets/images/gallery/__akvagrim/akvagrim4.jpg'
import akvagrim5 from '../../../assets/images/gallery/__akvagrim/akvagrim5.jpg'
import akvagrim from "../../../assets/images/akvagrim.jpg";
import {iContentPathItem} from "../types/type";

export const Akvagrim1Content: iContentPathItem = {
    titleImage: akvagrim,
    titleVideo: '',
    images: [
        {src: akvagrim1},
        {src: akvagrim2},
        {src: akvagrim3},
        {src: akvagrim4},
        {src: akvagrim5},
    ],
    videos: []
}