import React from "react";

import Lottie from "lottie-react";
import bgImage from "../../assets/images/bg/content_Happy-New-Year_Ellii.jpg";
import snowAnimation from "../../assets/lottie/snowAnimation.json";
import MoreButton from "../../shared/buttons/moreButton";
import { ComponentLayout } from "../../shared/section-layout/UI/component-layout";
import { SimpleTitle, titleAlign } from "../../shared/titles/simpleTitle";

interface NewYearProps {
	data: any;
}

export const NewYear: React.FC<NewYearProps> = (props) => {
	return (
		<>
			<ComponentLayout>
				<div id='NewYear'>
					<SimpleTitle
						title={
							"Новый год! Поздравление от Деда Мороза и Снегурочки!"
						}
						description={""}
						titleAlign={titleAlign.start}
					/>
				</div>
				<div className='flex gap-10 flex-col lg:flex-row'>
					<div
						style={{
							backgroundImage: `url(` + bgImage + `)`,
						}}
						className='object-fill lg:mt-0 mt-28 bg-cover bg-no-repeat bg-center rounded-[50px] flex flex-col gap-12 mx-auto py-32 w-full lg:w-2/3 lg:h-96 justify-center items-center text-center bg-yellow-200 relative  h-64'
					>
						<div className='hidden lg:block h-96 w-96 bottom-0 left-0 absolute z-30'>
							<Lottie animationData={snowAnimation} />
						</div>
						<div className='hidden lg:block lg:h-96 lg:w-96 top-0 right-0 absolute z-30 rotate-180'>
							<Lottie animationData={snowAnimation} />
						</div>
					</div>
					<div className='w-full lg:w-1/3 flex flex-col items-end justify-center text-end gap-10 lg:text-xl'>
						<p className='w-full md:w-2/3 lg:w-full'>
							Совсем скоро Новый Год. Самый долгожданный праздник
							в году, и какой же праздник без Деда Мороза и
							Снегурочки?
						</p>
						<div className='relative'>
							{/* <div className='lg:block h-16 w-16 top-0 left-0 absolute z-30'>
								<Lottie animationData={snowAnimation} />
							</div>*/}
							<MoreButton title='Подробнее' />
						</div>
					</div>
				</div>
			</ComponentLayout>
		</>
	);
};
