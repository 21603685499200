import spiderman_v1 from '../../../assets/images/gallery/spiderman/spiderman_v1.mp4'
import {iContentPathItem} from "../types/type";
import spidermanImage from '../../../assets/images/animations/min/spiderman-min.jpg'
export const SpidermanContent: iContentPathItem = {
    titleImage: spidermanImage,
    titleVideo: '',
    images: [],
    videos: [
        {src: spiderman_v1}
    ]
}