import elza1 from '../../../assets/images/gallery/elza/elza.jpg'
import {iContentPathItem} from "../types/type";
import elsaImage from '../../../assets/images/animations/min/IMG_7318-min.jpg'
export const ElsaContent: iContentPathItem = {
    titleImage: elsaImage,
    titleVideo: '',
    images: [
        {src: elza1},
    ],
    videos: []
}