import React from 'react';
import {Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {ComponentLayout} from "../../../shared/section-layout/UI/component-layout";
import {SimpleTitle, titleAlign} from "../../../shared/titles/simpleTitle";


interface GalleryProps {
    children?: string | JSX.Element | JSX.Element[]
    data: any
}

export const GalleryType3: React.FC<GalleryProps> = props => {
    return (
        <>
            <style>
                {':root {' +
                    '    --swiper-theme-color: orange;' +
                    '}' +
                    '.swiper-button-next, .swiper-button-prev {' +
                    'background: white;' +
                    'border-radius: 100%;' +
                    'padding: 2rem' +
                    '}' +
                    '.swiper-button-prev::after, .swiper-button-next::after{' +
                    'font-size: 25px;' +
                    '}' +
                    ''}
            </style>
            <ComponentLayout>
                <SimpleTitle
                    title={'Отзывы'}
                    description={'Мы надеемся что отзывы другх людей помогут вам определиться с выбором лучшего аниматора для вашего ребенка '}
                    titleAlign={titleAlign.center}
                />
                <div className="hidden lg:flex flex gap-4 itms-center">
                    <Swiper
                        navigation={true}
                        loop={true}
                        modules={[Pagination, Navigation]}
                        className={'w-3/4 h-fit '}
                        spaceBetween={50}
                        slidesPerView={1}
                    >
                        {props.data.map((el: any, index: any) => {
                            return <SwiperSlide key={index}>
                                <div style={{background: '#ff6d9e'}} className="h-[500px] rounded-2xl">
                                    <img
                                        loading="lazy"
                                        className={'object-contain w-full h-full'}
                                        src={el}
                                        alt="image"
                                    />
                                </div>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
                <div className="lg:hidden w-full flex gap-4 itms-center">
                    <Swiper
                        // navigation={true}
                        loop={true}
                        modules={[Pagination, Navigation]}
                        className={'w-full h-fit '}
                        spaceBetween={20}
                        slidesPerView={1}
                    >
                        {props.data.map((el: any, index: any) => {
                            return <SwiperSlide key={index}>
                                <div style={{background: '#ff6d9e'}} className="h-[500px] rounded-2xl">
                                    <img
                                        loading="lazy"
                                        className={'object-contain w-full h-full'}
                                        src={el}
                                        alt="image"
                                    />
                                </div>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
            </ComponentLayout>
        </>
    )
};