import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {ImagePreloader} from "../../../shared/images/imagePreloader";
import {AnimationData} from "../data/data";
import {iProgramsDataItem} from "../types/types";

interface ProgramsPageProps {

}

export const AnimationPage: React.FC<ProgramsPageProps> = () => {
    const {id} = useParams()
    //
    const [data, setData] = useState<iProgramsDataItem>();
    //
    useEffect(() => {
        setData((el: any) => {
            return AnimationData.filter((el) => el.anchor === id)[0]
        })
    }, [id]);
    //
    if (data) {
        console.log('data', data)
        return (
            <>
                <style>
                    {':root {' +
                        '    --swiper-theme-color: orange;' +
                        '}' +
                        '.swiper-button-next{' +
                        '' +
                        '}' +
                        '.swiper-button-next, .swiper-button-prev {' +
                        'background: white;' +
                        'border-radius: 100%;' +
                        'padding: 2rem;' +
                        'z-index: 50;' +
                        '}' +
                        '.swiper-button-prev::after, .swiper-button-next::after{' +
                        'font-size: 25px;' +
                        '}' +
                        '.swiper-pagination{' +
                        'top: 10px;' +
                        '' +
                        '}'}
                </style>
                <section className="w-screen min-h-[calc(100vh)] py-64 flex flex-col ">
                    <div className="container mx-auto gap-32 flex items-center flex-col">
                        <div className="space-y-6 text-center">
                            <h1 className={'text-3xl'}>{data.pageContent.title}</h1>
                            <p>{data.pageContent.description}</p>
                        </div>
                        <div className="">
                            <ul className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-center px-2'}>
                                {
                                    data.pageContent.images.map((el: any) => {
                                        return <li className={'w-auto h-96 overflow-hidden rounded-xl'}>
                                            <ImagePreloader image={el.src}/>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        {
                            data.pageContent.videos.length > 0
                                ?
                                <div className="flex items-center w-1/2 h-96 justify-between">
                                    <button className={'prev bg-white w-10 h-10 rounded-full'}>
                                        <svg
                                            className={'rotate-180'}
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="48"
                                            viewBox="0 -960 960 960"
                                            width="48"
                                        >
                                            <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z"/>
                                        </svg>
                                    </button>

                                    <Swiper
                                        navigation={{
                                            nextEl: ".next",
                                            prevEl: '.prev'
                                        }}
                                        loop={true}
                                        modules={[Pagination, Navigation]}
                                        className={'w-96 h-fit'}
                                        spaceBetween={10}
                                        slidesPerView={1}
                                        noSwiping={true}
                                    >
                                        {data.pageContent.videos.map((el, index) => {
                                            return <SwiperSlide key={index}>
                                                <div className="rounded-2xl overflow-hidden flex justify-center">
                                                    <video
                                                        className={'h-96 rounded-xl'}
                                                        controls={true}
                                                        src={el.src}
                                                    ></video>
                                                </div>
                                            </SwiperSlide>
                                        })}
                                    </Swiper>

                                    <button className={'next bg-white w-10 h-10 rounded-full'}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="48"
                                            viewBox="0 -960 960 960"
                                            width="48"
                                        >
                                            <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z"/>
                                        </svg>
                                    </button>
                                </div>
                                :
                                null
                        }
                    </div>
                </section>
            </>
        );
    } else {
        return null
    }

};