import React from 'react';
import ReactDOM from 'react-dom/client';
import './processes/index.css';
import App from './app/UI/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>
);
reportWebVitals();
