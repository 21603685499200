import React from 'react';
import Lottie from "lottie-react";
import {ImagePreloader} from "../../../shared/images/imagePreloader";
import {ComponentLayout} from "../../../shared/section-layout/UI/component-layout";
import {SimpleTitle, titleAlign} from "../../../shared/titles/simpleTitle";

import {ProductsGridProps} from '../types/types';
import {ProductItem} from "./items/product-item";

import celebrationAnimation from "../../../assets/lottie/6902-exploding-ribbon-and-confetti.json"
import additionalAnimation from "../../../assets/lottie/133137-party-hats.json"

export const ProductsGridType2: React.FC<ProductsGridProps> = (props) => {
    return (
        <ComponentLayout addClass='relative '>
              <div className='absolute -top-28 w-64 lg:w-96 left-1/2 -translate-x-1/2 '>
                    <Lottie animationData={celebrationAnimation} />
                </div>
            <SimpleTitle
                title={props.data.title}
                description={props.data.description}
                titleAlign={titleAlign.center}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4 w-full">
                {props.data.content.map((el: any, index) => {
                    return (
                        <a key={index} href={'#' + el.linkTo}>
                            <ProductItem
                                addClass={el.addClass}
                                mainTitle={el.mainTitle}
                            >
                                <ImagePreloader image={el.image}/>
                            </ProductItem>
                        </a>
                    )
                })}
            </div>
            <div className='absolute -bottom-36 right-36 w-96 '>
                    <Lottie animationData={additionalAnimation} />
                </div>
        </ComponentLayout>
    )

};