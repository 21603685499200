import prostokvashino_v1 from '../../../assets/images/gallery/prostokvashino/prostokvashino_v1.mp4'
import {iContentPathItem} from "../types/type";
import prostokvashinoImage from '../../../assets/images/animations/min/prostokvashino_28b-min.jpg'

import prostokvashino1 from '../../../assets/images/gallery/prostokvashino/prostokvashino1.jpg'
import prostokvashino2 from '../../../assets/images/gallery/prostokvashino/prostokvashino2.jpg'
import prostokvashino3 from '../../../assets/images/gallery/prostokvashino/prostokvashino3.jpg'
import prostokvashino4 from '../../../assets/images/gallery/prostokvashino/prostokvashino4.jpg'
import prostokvashino5 from '../../../assets/images/gallery/prostokvashino/prostokvashino5.jpg'
import prostokvashino6 from '../../../assets/images/gallery/prostokvashino/prostokvashino6.jpg'
import prostokvashino7 from '../../../assets/images/gallery/prostokvashino/prostokvashino7.jpg'
import prostokvashino8 from '../../../assets/images/gallery/prostokvashino/prostokvashino8.jpg'

export const ProstokvashinoContent: iContentPathItem = {
    titleImage: prostokvashinoImage,
    titleVideo: '',
    images: [
        {src: prostokvashino1},
        {src: prostokvashino2},
        {src: prostokvashino3},
        {src: prostokvashino4},
        {src: prostokvashino5},
        {src: prostokvashino6},
        {src: prostokvashino7},
        {src: prostokvashino8},
    ],
    videos: [
        {src: prostokvashino_v1},
    ]
}