//
import dogspatruleTitleImage from "../../../assets/images/animations/dogspatrule.webp";
//
import dogs_patrule1 from "../../../assets/images/gallery/dogs_patrule/dogs_patrule1.jpg";
import dogs_patrule2 from "../../../assets/images/gallery/dogs_patrule/dogs_patrule2.jpg";
import dogs_patrule3 from "../../../assets/images/gallery/dogs_patrule/dogs_patrule3.jpg";
import dogs_patrule4 from "../../../assets/images/gallery/dogs_patrule/dogs_patrule4.jpg";
import dogs_patrule5 from "../../../assets/images/gallery/dogs_patrule/dogs_patrule5.jpg";
import dogs_patrule6 from "../../../assets/images/gallery/dogs_patrule/dogs_patrule6.jpg";
import dogs_patrule7 from "../../../assets/images/gallery/dogs_patrule/dogs_patrule7.jpg";
//
import dogs_patrule_v1 from "../../../assets/images/gallery/dogs_patrule/dogs_patrule_v1.mp4";
import dogs_patrule_v2 from "../../../assets/images/gallery/dogs_patrule/dogs_patrule_v2.mp4";
import { iContentPathItem } from "../types/type";

export const DogspatruleContent: iContentPathItem = {
	titleImage: dogspatruleTitleImage,
	titleVideo: "",
	images: [
		{ src: dogs_patrule1 },
		{ src: dogs_patrule2 },
		{ src: dogs_patrule3 },
		{ src: dogs_patrule4 },
		{ src: dogs_patrule5 },
		{ src: dogs_patrule6 },
		{ src: dogs_patrule7 },
	],
	videos: [{ src: dogs_patrule_v1 }, { src: dogs_patrule_v2 }],
};
