import graviti_falze1 from '../../../assets/images/gallery/graviti_falze/graviti_falze1.jpg'
import graviti_falze2 from '../../../assets/images/gallery/graviti_falze/graviti_falze2.jpg'
import graviti_falze_v1 from '../../../assets/images/gallery/graviti_falze/graviti_falze_v1.mp4'
import {iContentPathItem} from "../types/type";
import mabel_n_deeperImage from '../../../assets/images/animations/min/IMG_7724-min.jpg'

export const Mabel_n_deeperContent: iContentPathItem = {
    titleImage: mabel_n_deeperImage,
    titleVideo: '',
    images: [
        {src: graviti_falze1},
        {src: graviti_falze2},
    ],
    videos: [
        {src: graviti_falze_v1},
    ]
}