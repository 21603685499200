import {featureIconColor} from "../../../shared/icons/feature-icon-type1";

export enum featuresAlign {
    Left = 'left',
    Right = 'right'
}

export interface iFeaturesDataItem {
    title?: string
    description?: string
    iconColor?: featureIconColor
    iconSource: string
}

export interface iFeaturesData {
    bgImage: string
    mainImage: string
    content: iFeaturesDataItem[]
}

export interface FeaturesProps {
    children?: string | JSX.Element | JSX.Element[]
    align: featuresAlign,
    data: iFeaturesData
}


