import among_us1 from '../../../assets/images/gallery/among_us/among_us1.jpg'
import among_us2 from '../../../assets/images/gallery/among_us/among_us2.jpg'
import among_us3 from '../../../assets/images/gallery/among_us/among_us3.jpg'
import {iContentPathItem} from "../types/type";
import among_usImage from '../../../assets/images/animations/min/among_us-min.jpg'
export const Among_usContent: iContentPathItem = {
    titleImage: among_usImage,
    titleVideo: '',
    images: [
        {src: among_us1},
        {src: among_us2},
        {src: among_us3},
    ],
    videos: [

    ]
}