import React from 'react';

interface ComponentLayoutProps {
    children?: string | JSX.Element | JSX.Element[]
    addClass?: string
    bgColor?: string
    bgImage?: string
    anchor?: string
}

export const ComponentLayout: React.FC<ComponentLayoutProps> = props => {
    return (
        <>
            <section
                style={props.bgImage ? {
                    backgroundImage: `url(`+props.bgImage+`)` 
                } : {}}
                className={(props.bgColor || '') + ' bg-cover bg-no-repeat bg-center w-full py-6 px-2 flex min-h-[calc(100vh)] ' + (props.addClass || '')}
            >
                <div
                style={props.bgImage ? {
                    backgroundColor: 'white' 
                } : {}}
                className="container mx-auto w-full flex flex-col gap-12 m-auto">
                    {props.children}
                </div>
            </section>
        </>
    );
};