import React from 'react';
import {ImagePreloader} from "../images/imagePreloader";

interface WwwIconProps {
    children?: string | JSX.Element | JSX.Element[]
    title: string
    image: string
}

export const WwwIcon: React.FC<WwwIconProps> = props => {
    return (
        <>
            <li className="w-full flex flex-col lg:flex-row items-center gap-4 text-start justify-start lg:p-0 p-4">
                <div className="lg:w-32 lg:h-32 flex overflow-hidden shadow-lg bg-white">
                    <ImagePreloader image={props.image}/>
                </div>
                <p className={'text-center lg:text-start text-lg lg:w-full w-2/3'}>{props.title}</p>
            </li>
        </>
    );
};