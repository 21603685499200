import {iContentPathItem} from "../types/type";
import super_famaly1 from '../../../assets/images/gallery/super_famaly/super_famaly1.jpg'
import super_famaly2 from '../../../assets/images/gallery/super_famaly/super_famaly2.jpg'
import super_famaly3 from '../../../assets/images/gallery/super_famaly/super_famaly3.jpg'
import superfamalyImage from '../../../assets/images/animations/min/superfamaly-min.jpg'
export const SuperfamalyContent: iContentPathItem = {
    titleImage: superfamalyImage,
    titleVideo: '',
    images: [
        {src: super_famaly1},
        {src: super_famaly2},
        {src: super_famaly3}
    ],
    videos: [

    ]
}