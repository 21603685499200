import React from 'react';

interface VideoLayoutProps {
    children?: string | JSX.Element | JSX.Element[]
    src: string
}

export const VideoLayout: React.FC<VideoLayoutProps> = props => {
    return (
        <>
            <div className="w-full h-auto rounded-2xl overflow-hidden">
                <video controls={true} src={props.src}></video>
            </div>
        </>
    );
};