import {ContentPath} from "../../../shared/contetntPath/ContentPath";
import {iProgramsDataItem} from "../types/types";
//
export const ShowmanData: iProgramsDataItem[] = [
    {
        anchor: '_',
        mainTitle: '',
        addClass: '',
        image: '',
        pageContent: {
            title: '',
            description:
                <></>,
            images: [],
            videos: []
        }
    },
    {
        anchor: 'showman',
        mainTitle: 'Ведущий на День рождения',
        addClass: '',
        image: ContentPath.Leading1Content.contentItem.titleImage,
        pageContent: {
            title: '« Ведущий на День рождения »',
            description:
                <>
                    <p>
                    Дети-взрослые - если ваш ребенок вырос из обычной анимации и для него это уже не интересно, у нас есть для вас решение, услуга “дети-взрослые”. К вам на день рождение приедет ведущий с профессиональным оборудованием, проектором, экраном и диджеем. С подготовленной программой, которая будет интересна не только детям, но и взрослым. Конкурсы, челленджи, быстрые задания, танцевальные флешмобы, дискотека с современными хитами (музыку можете заказать у диджея какая вам нравится), тосты для взрослых, интеллектуальные конкурсы и многое другое будет на вашем празднике. ведущий будет работать как с детьми так и взрослыми, охватит всех гостей и вовлекать их в участие в конкурсах. Ваш день рождение пройдет на высшем уровне. 

                    </p>
                    <ul className="my-4">
                        <li>showman</li>
                        <li>showman</li>
                    </ul>
                </>,
            images: ContentPath.Leading1Content.contentItem.images,
            videos: ContentPath.Leading1Content.contentItem.videos,
        }
    },
]