import React, {useEffect, useState} from 'react';
import {staticInfo} from "../../../app/API/staticInfo";
import {Logo} from "../../../shared/icons/logo";
import {Address} from "../../../shared/labels/address/address";
import {OrgName} from "../../../shared/labels/org-name/org-name";
import {PhoneNumber} from "../../../shared/labels/phone-number/phone-number";
import {Worktime} from "../../../shared/labels/worktime/worktime";
import {Navbar} from "../../navbar/UI/navbar";
import {listenToScroll} from "../logic/logic";


interface HeaderProps {
    children?: string | JSX.Element | JSX.Element[]
}


export const Header: React.FC<HeaderProps> = () => {
    //
    const [isVisible, setIsVisible] = useState<boolean>(true);
    //

    //
    useEffect(() => {
        window.addEventListener("scroll", () => {
            listenToScroll(setIsVisible)
        });
        return () =>
            window.removeEventListener("scroll", () => {
                listenToScroll(setIsVisible)
            });
    }, [])
    //
    return (
        <>
            <div className={(isVisible ? '' : ' -translate-y-[128px] ') + " duration-150 flex flex-col w-full fixed top-0 left-0 bg-white z-20"}>
                <div className={"h-[128px] px-2 lg:px-0 w-full py-4 container mx-auto bg-neutral flex flex-row justify-between items-center"}>
                    <div className="flex gap-12 items-center">
                        <Logo/>
                        <OrgName/>
                        <Address/>
                    </div>
                    <div className="flex flex-col gap-2 lg:flex-row lg:gap-12 items-end ">
                        <Worktime/>
                        <PhoneNumber phone={staticInfo.phone[0]}/>
                    </div>
                </div>
                <Navbar/>
            </div>

        </>
    );
};