import React from 'react';
import {staticInfo} from "../../../app/API/staticInfo";
import {Logo} from "../../../shared/icons/logo";
import {OrgName} from "../../../shared/labels/org-name/org-name";
import {PhoneNumber} from "../../../shared/labels/phone-number/phone-number";
import {Worktime} from "../../../shared/labels/worktime/worktime";

import instIcon from './../../../assets/icons/social/instagram.png'

interface FooterProps {
    children?: string | JSX.Element | JSX.Element[]
}

export const Footer: React.FC<FooterProps> = () => {
    return (
        <div className="w-full py-8 container mx-auto bg-neutral flex flex-col xl:flex-row justify-between items-center gap-6 xl:gap-0">
            <div className="flex items-center space-x-6">
                <Logo/>
                <OrgName/>
            </div>
            <div className="flex flex-col gap-2 items-center md:py-4 md:px-8 rounded-xl ">
                <p>Присоединяйтесь к нам!</p>
                <div className="flex gap-6 items-center">
                    <a href="https://www.instagram.com/event.tochka/" target={'blank'}>
                        <img className={'w-8 h-8 hover:scale-125 duration-150'} src={instIcon} alt="icon"/>
                    </a>
                    {/* <img className={'w-8 h-8 hover:scale-125 duration-150'} src={vkIcon} alt="icon"/> */}
                </div>
            </div>
            <div className="flex gap-6 lg:gap-12 flex-col lg:flex-row">
                <Worktime/>
                <PhoneNumber phone={staticInfo.phone[0]}/>
            </div>
        </div>
    );
};