import {ContentPath} from "../../../shared/contetntPath/ContentPath";
import {iProgramsDataItem} from "../types/types";
//
export const MasterclassShowData: iProgramsDataItem[] = [
    {
        anchor: 'silverShow',
        mainTitle: '«Серебряное шоу»',
        addClass: '',
        image: ContentPath.Show_silver1Content.contentItem.titleImage,
        pageContent: {
            title: '«Серебряное шоу»',
            description:
                <>
                    <p>
                    Серебряное шоу - желаете устроить незабываемую дискотеку под современные хиты, почувствовать бурю положительных эмоций, сделать классные фото и просто повеселиться от души? 
Серебряная дискотека это то, что безоговорочно подарит вам все это и оставит положительные воспоминания надолго. 
Серебряные ленты которыми можно закидать друг друга, сделать серебряный салют, прыгнуть в гору серебра, станцевать в них зажигательные танцы это все и многое другое, что так любят дети и взрослые подарит вам данное шоу.
                    </p>
                </>,
            images: ContentPath.Show_silver1Content.contentItem.images,
            videos: ContentPath.Show_silver1Content.contentItem.videos,
        }
    },
    {
        anchor: 'bubbles',
        mainTitle: '«Шоу гигантских мыльных пузырей»',
        addClass: '',
        image: ContentPath.Show_bubbles1Content.contentItem.titleImage,
        pageContent: {
            title: '«Шоу гигантских мыльных пузырей»',
            description:
                <>
                    <p>
                    Шоу гигантских мыльных пузырей - одно из самых любимых шоу всех возрастов. От него приходят в восторг не только дети, но и взрослые. В этом шоу вы увидите все размеры мыльных, от самого маленького, до самого огромного в который поместится человек в полный рост,  также загадать желание держа в руках волшебную пену или сделать из нее снежный мини-салют. А тем кому хватит смелости, кто ничего не боится можно подержать в руках настоящие огненные пузыри, эффект которых вы никогда не забудете. 

                    </p>
                   
                </>,
            images: ContentPath.Show_bubbles1Content.contentItem.images,
            videos: ContentPath.Show_bubbles1Content.contentItem.videos,
        }
    },
    {
        anchor: 'slime',
        mainTitle: '“Мастер класс по слаймам”',
        addClass: '',
        image: ContentPath.SlimeContent.contentItem.titleImage,
        pageContent: {
            title: '«Мастер класс по слаймам»',
            description:
                <>
                    <p>
                    Мастер класс по слаймам - Слайм одна из самых популярных игрушек в мире. Вопреки мнениям что она бесполезна, на самом деле она является прекрасным антистрессом и отлично развивает моторику.  Дети обожают эти игрушки, а у некоторых есть даже целые коллекции. 
Но что если попробовать сделать это своими руками?  Любой ребенок будет в восторге от такого предложения. Благодаря нашей уникальной рецептуре дети смогут своими руками сделать себе слайм и украсить его так как сами захотят. Это будет один из самых ярких и незабываемых подарков. 

                    </p>
                   
                </>,
            images: ContentPath.SlimeContent.contentItem.images,
            videos: ContentPath.SlimeContent.contentItem.videos,
        }
    },
]