import { ContentPath } from "../../shared/contetntPath/ContentPath";
import { iNewYearData } from "./types";

//
export const NewYearData: iNewYearData[] = [
	{
		anchor: "newYear",
		mainTitle: "«Поздравление деда Мороза»",
		addClass: "",
		image: ContentPath.NewYear.contentItem.titleImage,
		pageContent: {
			title: "Поздравление деда Мороза",
			description: (
				<>
					<p className="p-2">
						Совсем скоро Новый Год. Самый долгожданный праздник в
						году, и какой же праздник без Деда Мороза и Снегурочки?
						Кто зажжет елочку и будет развлекать, и дарить подарки
						как не эти два затейника? Ведь это именно то время,
						когда они подарят вам настоящую сказку и незабываемые
						эмоции. Профессиональный Дед Мороз и Снегурочка в
						шикарных костюмах, сделают ваше торжество незабываемым.
					</p>
					<div className='pt-10 flex flex-col gap-4 p-2'>
						<p className='text-xl'>Куда нас можно приглашать:</p>
						<ul className='flex flex-col gap-2 items-center'>
							<li className='px-4 py-2 bg-sky-100 rounded-xl w-fit'>
								"Утренник в детский сад (мы выучим ваш сценарий
								и приедем к вам)"
							</li>
							<li className='px-4 py-2 bg-sky-100 rounded-xl w-fit'>
								"Домой ( две уникальные и очень не типичные
								программы для деток и их родителей)"
							</li>
							<li className='px-4 py-2 bg-sky-100 rounded-xl w-fit'>
								"Во двор вашего дома (Хотите провести праздник
								всем двором? Мы с легкостью это сделаем)"
							</li>
							<li className='px-4 py-2 bg-sky-100 rounded-xl w-fit'>
								"Корпоратив (Зажигательная программа для
								взрослых) "
							</li>
							<li className='px-4 py-2 bg-sky-100 rounded-xl w-fit'>
								"День Рождение (если в новогодний период у вас
								или у ваших близких день рождение, это самый
								интересный способ поздравить именинника)"
							</li>
						</ul>
					</div>
				</>
			),
			images: ContentPath.NewYear.contentItem.images,
			videos: ContentPath.NewYear.contentItem.videos,
		},
	},
];
