import React from 'react';
import { NavItemProps } from './type';
import { Link } from 'react-router-dom';



export const NavItemNewYear: React.FC<NavItemProps> = ({anchor,name}) => {
    return (
        <>
            <li className={'hover:cursor-pointer lg:hover:scale-105 duration-150 text-lg lg:text-sm w-fit whitespace-nowrap py-2 px-4 text-white rounded-full  bg-gradient-to-r from-purple-500 to-blue-500'}>
                <a className={''} href={'/#' + anchor}>{name}</a>
            </li>
        </>
    );
};