import React from "react";
import { FeatureIconType2 } from "../../../shared/icons/feature-icon-type2";
import { ImagePreloader } from "../../../shared/images/imagePreloader";
import { ComponentLayout } from "../../../shared/section-layout/UI/component-layout";
import { SimpleTitle, titleAlign } from "../../../shared/titles/simpleTitle";
import { featuresAlign, iFeaturesData } from "../interface/unterface";

interface FeaturesProps {
	children?: string | JSX.Element | JSX.Element[];
	align: featuresAlign;
	data: iFeaturesData;
}

export const FeaturesType2: React.FC<FeaturesProps> = (props) => {
	return (
		<>
			<ComponentLayout bgImage={props.data.bgImage}>
				<SimpleTitle
					title={"Почему стоит выбрать именно нас:"}
					description={
						"Работа с Нами предоставляет множество преимуществ, которые связаны с нашим профессионализмом и мастерством. Когда дело касается организации детского мероприятия, качество и опыт играют решающую роль в создании незабываемого праздника для детей."
					}
					titleAlign={titleAlign.start}
				/>
				<div className={"flex w-full gap-4 lg:flex-row flex-col"}>
					<div className='lg:w-1/3 flex shrink'>
						<div className='overflow-hidden'>
							<ImagePreloader image={props.data.mainImage} />
						</div>
					</div>
					<div
						className={
							(props.align === "left" ? " -order-1 " : "") +
							" lg:w-2/3 flex"
						}
					>
						<ul
							className={
								"grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12"
							}
						>
							{props?.data?.content?.map((el, index) => {
								return (
									<li
										key={index}
										className={
											"flex flex-row gap-8 items-center"
										}
									>
										<FeatureIconType2
											iconSource={el.iconSource}
										/>
										<p>{el.description}</p>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</ComponentLayout>
		</>
	);
};
