//
import rep_1 from '../../../assets/images/photo-report/rep_1.jpg'
import rep_2 from '../../../assets/images/photo-report/rep_2.jpg'
import rep_3 from '../../../assets/images/photo-report/rep_3.jpg'
import rep_4 from '../../../assets/images/photo-report/rep_4.jpg'
import rep_5 from '../../../assets/images/photo-report/rep_5.jpg'
import rep_6 from '../../../assets/images/photo-report/rep_6.jpg'
import rev_1 from '../../../assets/images/reviews/rev_1.png'
import rev_2 from '../../../assets/images/reviews/rev_2.png'
import rev_3 from '../../../assets/images/reviews/rev_3.png'
import rev_4 from '../../../assets/images/reviews/rev_4.png'
import rev_5 from '../../../assets/images/reviews/rev_5.png'

export const GalleryData = {
    reviews: [rev_1, rev_2, rev_3, rev_4, rev_5],
    photoReports: [rep_1, rep_2, rep_3, rep_4, rep_5, rep_6,]
}