import mikki_maisImage from '../../../assets/images/animations/min/mikki-maus-min.jpg'
import mikki1 from '../../../assets/images/gallery/mikki/mikki1.jpg'
import {iContentPathItem} from "../types/type";

export const Mikki_maisContent: iContentPathItem = {
    titleImage: mikki_maisImage,
    titleVideo: '',
    images: [
        {src: mikki1},
    ],
    videos: []
}