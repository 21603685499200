import React from 'react';

interface StepsIconProps {
    children?: string | JSX.Element | JSX.Element[]
    index: number
}

export const StepsIcon: React.FC<StepsIconProps> = props => {
    return (
        <>
            <p className="w-24 h-24 flex rounded-full shadow-lg items-center justify-center text-3xl bg-red-100 font-bold leading-none">
                {props.index}
            </p>
        </>
    );
};