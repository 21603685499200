import barbe1 from '../../../assets/images/gallery/barby/barby1.jpg'
import barbe2 from '../../../assets/images/gallery/barby/barby2.jpg'
// import uensday_v1 from '../../../assets/images/gallery/uensday/uensday_v1.mp4'
import barbeImage from '../../../assets/images/animations/min/barby.jpg'
import {iContentPathItem} from "../types/type";

export const BarbyContent: iContentPathItem = {
    titleImage: barbeImage,
    titleVideo: '',
    images: [
        {src: barbe1},
        {src: barbe2},
    ],
    videos: [
        
    ]
}