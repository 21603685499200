
import { ComponentLayout } from "../../shared/section-layout/UI/component-layout";
import Lottie from "lottie-react";

import celebrationAnimation from "../../assets/lottie/animation_lml1g763.json"

function NotFound() {
    return ( 
    <>
         <ComponentLayout>
            <div className="flex">
                <div className="w-1/2 m-auto text-center">
                <Lottie animationData={celebrationAnimation} />
                <h2>Уппсс! Такой станицы нет. Посмотриде другие наши евенты пожалуйста =)</h2>
                </div>
            </div>
            
        </ComponentLayout>
    </> 
    );
}

export default NotFound;