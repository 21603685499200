import React from 'react';

interface AddressProps {
    children?: string | JSX.Element | JSX.Element[]
}

export const Address: React.FC<AddressProps> = props => {
    return (
        <>
            <p className={'hidden lg:block'}>г. Минск, <br/> ул. Змитрока Бядули,д. 15, ком. 4 </p>
        </>
    );
};