import det_center from '../../../assets/images/whereWeWork/det_center-min.jpg'
import dom from '../../../assets/images/whereWeWork/dom-min.jpg'
import det_sad from '../../../assets/images/whereWeWork/det_sad-min.jpg'
import kafe from '../../../assets/images/whereWeWork/kafe-min.jpg'
import park from '../../../assets/images/whereWeWork/park-min.jpg'
import kvartira from '../../../assets/images/whereWeWork/kvartira-min.jpg'
import {iWWWItem} from "../types/types";
//
export const wwwImages = {
    det_center: det_center, dom: dom, det_sad: det_sad, kafe: kafe, park: park, kvartira: kvartira
}

export const locationListData:iWWWItem[] = [
    {title: 'В КАФЕ/РЕСТОРАНЕ', icon: wwwImages.kafe},
    {title: 'В ПАРКЕ', icon: wwwImages.park},
    {title: 'В ДЕТСКОМ САДУ/ШКОЛЕ', icon: wwwImages.det_sad},
    {title: 'В ЧАСТНОМ ДОМЕ/НА ДАЧЕ', icon: wwwImages.dom},
    {title: 'В КВАРТИРЕ', icon: wwwImages.kvartira},
    {title: 'В ДЕТСКОМ ЦЕНТРЕ', icon: wwwImages.det_center},
]