import airball from "../../../assets/images/airball.jpg";
import air_balloons1 from '../../../assets/images/gallery/__air_balloons/air_balloons1.jpg'
import air_balloons2 from '../../../assets/images/gallery/__air_balloons/air_balloons2.jpg'
import air_balloons3 from '../../../assets/images/gallery/__air_balloons/air_balloons3.jpg'
import {iContentPathItem} from "../types/type";

export const Air_balloons1Content: iContentPathItem = {
    titleImage: airball,
    titleVideo: '',
    images: [
        {src: air_balloons1},
        {src: air_balloons2},
        {src: air_balloons3},
    ],
    videos: []
}