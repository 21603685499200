import Lottie from 'lottie-react';
import React from 'react';
import {Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
//
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
//
import bgAnimation from "../../../assets/lottie/61417-animated-emojis-party-emoji.json"
import celebrationAnimation from "../../../assets/lottie/70203-colorful-balloons.json"
import celebrationAnimation2 from "../../../assets/lottie/98537-celebrate.json"
//
// @ts-ignore
import videoRep_1 from '../../../assets/videos/rep/video_report_1.mp4'
// @ts-ignore
import videoRep_2 from '../../../assets/videos/rep/video_report_2.mp4'
// @ts-ignore
import videoRep_3 from '../../../assets/videos/rep/video_report_3.mp4'
// @ts-ignore
import videoRep_4 from '../../../assets/videos/rep/video_report_4.mp4'
// @ts-ignore
import videoRep_5 from '../../../assets/videos/rep/video_report_5.mp4'
// @ts-ignore
import videoRep_6 from '../../../assets/videos/rep/video_report_6.mp4'
// @ts-ignore
import videoRep_7 from '../../../assets/videos/rep/video_report_7.mp4'
import {ComponentLayout} from "../../../shared/section-layout/UI/component-layout";
import {SimpleTitle, titleAlign} from "../../../shared/titles/simpleTitle";
import {VideoLayout} from "../../../shared/video/video-layout";

interface GalleryProps {
    children?: string | JSX.Element | JSX.Element[]
    data: any
}

interface iGalleryItem {
    src: string
}

const galleryList: iGalleryItem[] = [
    {src: videoRep_1},
    {src: videoRep_2},
    {src: videoRep_3},
    {src: videoRep_4},
    {src: videoRep_5},
    {src: videoRep_6},
    {src: videoRep_7},

]

export const GalleryType1: React.FC<GalleryProps> = () => {
    //
    return (
        <>
            <style>
                {':root {' +
                    '    --swiper-theme-color: orange;' +
                    '}' +
                    '.swiper-button-next{' +
                    '' +
                    '}' +
                    '.swiper-button-next, .swiper-button-prev {' +
                    'background: white;' +
                    'border-radius: 100%;' +
                    'padding: 2rem;' +
                    'z-index: 50;' +
                    '}' +
                    '.swiper-button-prev::after, .swiper-button-next::after{' +
                    'font-size: 25px;' +
                    '}' +
                    '.swiper-pagination{' +
                    'top: 10px;' +
                    '' +
                    '}'}
            </style>
            <ComponentLayout addClass="relative">
                <SimpleTitle
                    title={'Видеоотчеты'}
                    description={'Мы стараемся соответствовать ожиданиям наших взрослых и маленьких клиентов. Вы можете сами в этом убедиться посмотрев видеоролики:'}
                    titleAlign={titleAlign.center}
                />
                <div className="w-full flex flex-col lg:flex-row gap-4">
                    <div className="absolute -left-12 top-0 -rotate-45 w-32 lg:w-96 -z-10 lg:z-0">
                        <Lottie animationData={celebrationAnimation2}/>
                    </div>
                    <div className="lg:w-2/4 flex flex-col items-center justify-center z-10">
                        <div className="flex flex-col items-center justify-center h-full gap-4 text-md lg:w-2/3 text-center lg:text-start">
                            <p>Видео отчет – это лишь малая часть того, что мы можем предложить вам. Мы всегда
                                готовы
                                помочь вам создать незабываемый праздник, который оставит яркие впечатления на всю
                                жизнь!</p>
                            <p>Наши аниматоры - профессионалы своего дела и создали уникальную атмосферу праздника,
                                которая запомнится всем гостям надолго.</p>
                            <p>Мы используем различные анимационные программы, чтобы сделать мероприятие еще более
                                интересным и увлекательным.</p>
                        </div>

                    </div>

                    <div className="-order-1 lg:order-1 lg:w-2/4 rounded-2xl py-12 flex justify-between items-center gap-2">
                        <button className={'prev bg-white shadow-lg w-10 h-10 rounded-full z-10 flex items-center justify-center'}>
                            <svg
                                className={'rotate-180'}
                                xmlns="http://www.w3.org/2000/svg"
                                height="48"
                                viewBox="0 -960 960 960"
                                width="48"
                            >
                                <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z"/>
                            </svg>
                        </button>
                        <Swiper
                            navigation={{
                                nextEl: ".next",
                                prevEl: '.prev'
                            }}
                            loop={true}
                            modules={[Pagination, Navigation]}
                            className={'h-fit w-96'}
                            spaceBetween={10}
                            slidesPerView={1}
                            noSwiping={true}
                        >
                            {galleryList.map((el, index) => {
                                return <SwiperSlide key={index}>
                                    <VideoLayout src={el.src}/>
                                </SwiperSlide>
                            })}
                        </Swiper>
                        <button className={'next bg-white shadow-lg w-10 h-10 rounded-full z-10 flex items-center justify-center'}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                                <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z"/>
                            </svg>
                        </button>
                    </div>
                    <div className="absolute -left-32 bottom-0 rotate-45 w-64 lg:w-96">
                        <Lottie animationData={celebrationAnimation}/>
                    </div>
                    <div className="absolute -left-32 bottom-0 rotate-45 w-64 lg:w-96">
                        <Lottie animationData={bgAnimation}/>
                    </div>
                </div>
            </ComponentLayout>

        </>
    )
};