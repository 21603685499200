import React from 'react';

interface PhoneNumberProps {
    children?: string | JSX.Element | JSX.Element[]
    phone: string
}

export const PhoneNumber: React.FC<PhoneNumberProps> = ({phone}) => {
    return (
        <>
            <a className={'w-fit text-white hover:cursor-pointer rounded-full py-3 px-5 bg-gradient-to-r from-cyan-500 to-blue-500'} href={'tel:'+phone} >{phone}</a>
        </>
    );
};