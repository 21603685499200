import Lottie from "lottie-react";
import React from "react";
import { Link } from "react-router-dom";
import celebrationAnimation from "../../../assets/lottie/98537-celebrate.json";
import { ImagePreloader } from "../../../shared/images/imagePreloader";
import { ComponentLayout } from "../../../shared/section-layout/UI/component-layout";
import { SimpleTitle, titleAlign } from "../../../shared/titles/simpleTitle";
import { ProductsGridProps } from "../types/types";
import { ProductItem } from "./items/product-item";

interface ProductsGridType1Props extends ProductsGridProps {
	isLinked: boolean;
	linkName: string;
}

export const ProductsGridType1: React.FC<ProductsGridType1Props> = (props) => {
	return (
		<>
			<SimpleTitle
				title={props.data.title}
				description={""}
				titleAlign={titleAlign.center}
			/>
			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4 w-full relative'>
				{/*{props.data?.content?.map((el: any, index) => {*/}
				{/*    return (*/}
				{/*        <ProductItem*/}
				{/*            key={index}*/}
				{/*            addClass={el.addClass}*/}
				{/*            mainTitle={el.mainTitle}*/}
				{/*        >*/}
				{/*            <ImagePreloader image={el.image}/>*/}
				{/*        </ProductItem>*/}
				{/*    )*/}
				{/*})}*/}

				{props.data.animFlag || (
					<>
						{/* {/* <div className="absolute left-0 top-0 rotate-180">
                            <Lottie animationData={celebrationAnimation}/>
                        </div> */}
						<div className='absolute right-0 top-0 rotate-180'>
							<Lottie animationData={celebrationAnimation} />
						</div>
					</>
				)}

				{props?.data?.content?.map((el: any, index) => {
					return el.mainTitle ? (
						props.isLinked ? (
							<Link to={props.linkName + "/" + el?.anchor}>
								<ProductItem
									key={index}
									addClass={el.addClass}
									mainTitle={el.mainTitle}
								>
									<ImagePreloader image={el.image} />
								</ProductItem>
							</Link>
						) : (
							<ProductItem
								key={index}
								addClass={el.addClass}
								mainTitle={el.mainTitle}
							>
								<ImagePreloader image={el.image} />
							</ProductItem>
						)
					) : (
						<div></div>
					);
				})}
			</div>
		</>
	);
};
