import React from 'react';

interface OrgNameProps {
    children?: string | JSX.Element | JSX.Element[]
}

export const OrgName: React.FC<OrgNameProps> = props => {
    return (
        <>
            <h3 className={'hidden md:block'}>Организация детских <br/> праздников в Беларуси</h3>
        </>
    );
};