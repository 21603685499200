import uensday1 from '../../../assets/images/gallery/uensday/uensday1.jpg'
import uensday2 from '../../../assets/images/gallery/uensday/uensday2.jpg'
import uensday4 from '../../../assets/images/gallery/uensday/uensday4.jpg'
import uensday_v1 from '../../../assets/images/gallery/uensday/uensday_v1.mp4'
import addam_1Image from '../../../assets/images/animations/min/addam1-min.jpg'
import {iContentPathItem} from "../types/type";

export const Addam_1Content: iContentPathItem = {
    titleImage: addam_1Image,
    titleVideo: '',
    images: [
        {src: uensday1},
        {src: uensday2},
        {src: uensday4},
    ],
    videos: [
        {src: uensday_v1},
    ]
}