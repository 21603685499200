import servicesLeading from "../../../assets/images/services/services_leading-min.jpg";
import servicesPersons from "../../../assets/images/services/services_persons-min.jpg";
import servicesShow from "../../../assets/images/services/sevices_show-min.jpg";
import {AdditionalServicesData} from "../../../pages/AdditionServices-page/data/data";
import {AnimationData} from "../../../pages/animation-page/data/data";
import { NewYearData } from "../../../pages/newYear/NewYearData";
import {MasterclassShowData} from "../../../pages/show-masterclass-page/data/data";
import {ShowmanData} from "../../../pages/showman-page/data/data";
//

//
export const ProductData = {
    ShowToYouCelebrity: {
        title: 'Шоу к вашему празднику',
        description: 'Мы предлагаем всевозможные тематики и сценарии, которые могут быть доработаны с вашими пожеланиями. Можем предложить ребенку любимую тематику или визуализацию какого-либо популярного мультфильма или книги. Это делает праздник индивидуальным и запоминающимся.',
        gridClass: 'grid-cols-1 lg:grid-cols-3',
        animFlag: false,
        content: [
            {
                mainTitle: 'Анимацмонные программы',
                addClass: '',
                image: servicesPersons,
                linkTo: 'service-animations'
            },
            {
                mainTitle: 'Шоу и мастерклассы',
                addClass: '',
                image: servicesShow,
                linkTo: 'service-shows'
            },
            {
                mainTitle: 'Ведущий',
                addClass: '',
                image: servicesLeading,
                linkTo: 'service-leading'
            },
        ]
    },
    AdditionServices: {
        title: 'Дополнительные услуги',
        description: '',
        gridClass: 'grid-cols-1 lg:grid-cols-3',
        animFlag: false,
        content: AdditionalServicesData
    },
    NewYear: {
        title: 'Новый год',
        description: '',
        gridClass: 'grid-cols-1 lg:grid-cols-3',
        animFlag: false,
        content: NewYearData
    },
    AnimationPrograms: {
        title: 'Анимационные программы',
        description: '',
        gridClass: 'grid-cols-1 lg:grid-cols-4',
        animFlag: false,
        content: AnimationData
    },
    Masterclass: {
        title: 'Шоу и мастерклассы:',
        description: '',
        gridClass: 'grid-cols-1 lg:grid-cols-3',
        animFlag: false,
        content: MasterclassShowData
    },
    Showman: {
        title: 'Ведущий',
        description: '',
        gridClass: 'grid-cols-1 lg:grid-cols-3',
        animFlag: true,
        content: ShowmanData
    },
}