import {ContentPath} from "../../../shared/contetntPath/ContentPath";
import {iProgramsDataItem} from "../types/types";
//
export const AnimationData: iProgramsDataItem[] = [
    {
        anchor: 'barby',
        mainTitle: '«Мини-квест Барби»',
        addClass: '',
        image: ContentPath.BarbyContent.contentItem.titleImage,
        pageContent: {
            title: '«Барби»',
            description:
                <>
                    <p>
                    Покинув Барбиленд, Барби оказывается в реальном мире, в котором ей очень сложно адаптироваться, но все же ей удается освоить эту жизнь. Но поскольку ей не хватает красок, ярких моментов и веселья, она решает научить и показать, как они веселятся в Барбилэнде.  
                    </p>
                    <ul className="my-4">
                        <li>- Знакомство с Барби;</li>
                        <li>- Аквагримм и стразы;</li>
                        <li>- Танец Барби;</li>
                        <li>- Дефиле на звездой дорожке;</li>
                        <li>- Переодевания в модные аксессуары;</li>
                        <li>- Специальный реквизит;</li>
                        <li>- Продуманный сценария;</li>
                        <li>- Фотосессия;</li>
                        <li>- Вынос тортика.</li>
                    </ul>
                    <ul>
                        <li>К программе можно добавить шоу мыльных пузырей, серебряную дискотеку, фигурные шарики.
                        </li>
                    </ul>
                </>,
            images: ContentPath.BarbyContent.contentItem.images,
            videos: ContentPath.BarbyContent.contentItem.videos,
        }
    },
    {
        anchor: 'dogspatrule',
        mainTitle: '«Щенячий патруль»',
        addClass: '',
        image: ContentPath.Dogspatrule.contentItem.titleImage,
        pageContent: {
            title: '«Щенячий патруль»',
            description:
                <>
                    <p>
                        Знаменитый щенки-спасатели, которые всегда спешат на помощь ко всем, кто в этом нуждается. Но,
                        они помогают не только тем кто попал в беду, но и тем у кого праздник, чтобы сделать его самым
                        веселым и незабываемым.
                    </p>
                    <ul className="my-4">
                        <li> - Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Задания и испытания;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Танцы-повторялки;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li>Программа на возраст от 3+</li>
                        <li>Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>,
            images: ContentPath.Dogspatrule.contentItem.images,
            videos: ContentPath.Dogspatrule.contentItem.videos,
        }
    },
    {
        anchor: 'xagi',
        mainTitle: '«Хаги Ваги и Киси Миси»',
        addClass: '',
        image: ContentPath.XagiContent.contentItem.titleImage,
        pageContent: {
            title: '«Хаги Ваги и Киси Миси»',
            description:
                <>
                    <p>
                        Два монстра, которые живут на заброшенной фабрике игрушек только и ждут, когда к ним кто-то
                        забредет. На вид они страшные и ужасные, но на самом деле очень дружелюбные и веселые, а пугают
                        они кого-то только ради веселья.
                    </p>
                    <ul className="my-4">
                        <li> - Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Задания и испытания;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Танцы-повторялки;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 5+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.XagiContent.contentItem.images,
            videos: ContentPath.XagiContent.contentItem.videos,
        }
    },
    {
        anchor: 'mikki_mais',
        mainTitle: '“Микки и Мини Маус”',
        addClass: '',
        image: ContentPath.Mikki_maisContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Самые известные мыши на планете земля. веселые, озорные, шустрые и просто смешные. Они сделают
                        ваш праздник незабываемым и очень веселым, так как у них в арсенале припрятано множество идей
                        как это сделать.
                    </p>
                    <ul className="my-4">
                        <li> - Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Задания и испытания;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Танцы-повторялки;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 3+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.Mikki_maisContent.contentItem.images,
            videos: ContentPath.Mikki_maisContent.contentItem.videos,
        }
    },
    {
        anchor: 'spiderman',
        mainTitle: '«Человек-паук»',
        addClass: '',
        image: ContentPath.SpidermanContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Самый известный и любимый супергерой, который славится своей проворностью, силой, умом и
                        ловкстью. На празднике с ним не заскучаешь, ведь у него в арсенале огромное количество игр, идей
                        и испытаний.
                    </p>
                    <ul className="my-4">
                        <li> - Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Супергеройские задания и испытания;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Танцы-повторялки;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 4+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.SpidermanContent.contentItem.images,
            videos: ContentPath.SpidermanContent.contentItem.videos,
        }
    },
    {
        anchor: 'superfamaly',
        mainTitle: '«Суперсемейка»',
        addClass: '',
        image: ContentPath.SuperfamalyContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Семейка супергероев, обладающая не только харизмой и силой но и отличным понимаем как должен
                        строиться праздник. Они превратят ваш праздник в незабываемое семейное веселье, в котором могут
                        принять участие не только дети, но и взрослые.
                    </p>
                    <ul className="my-4">
                        <li> - Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Прохождение школы супергероев;</li>
                        <li>- Супер-геройские задания и испытания;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Танцы-повторялки;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 5+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.SuperfamalyContent.contentItem.images,
            videos: ContentPath.SuperfamalyContent.contentItem.videos,
        }
    },
    {
        anchor: 'mabel_n_deeper',
        mainTitle: '«Мэйбл и Диппер»',
        addClass: '',
        image: ContentPath.Mabel_n_deeperContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Самый известные мальчик и девочка из мультика “Гравити Фолз”, которые очень любят различные
                        тайны, загадки и секреты. Они точно не дадут вам заскучать и погрузят в атмосферу таинственного
                        города “Гравити Фолз”.
                    </p>
                    <ul className="my-4">
                        <li>- Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Разгадка тайн города Гравити Фолз;</li>
                        <li>- Волшебные и веселые задания и испытания;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Зажигательные танцы;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 6+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.Mabel_n_deeperContent.contentItem.images,
            videos: ContentPath.Mabel_n_deeperContent.contentItem.videos,
        }
    },
    {
        anchor: 'elsa',
        mainTitle: '«Эльза. Холодное сердце»',
        addClass: '',
        image: ContentPath.ElsaContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Прекрасная Эльза, Анна, Олаф и Кристоф проведут вас в Эренделл и устроят там настоящий бал
                        принцесс. А весельчак Олаф заставит всех смеяться до слез, так как его харизма и задорный нрав
                        так и лезут наружу.

                    </p>
                    <ul className="my-4">
                        <li> - Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Прохождение школы миньонов;</li>
                        <li>- Веселые задания от миньонов ;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Танцы-повторялки;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 4+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.ElsaContent.contentItem.images,
            videos: ContentPath.ElsaContent.contentItem.videos,
        }
    },
    {
        anchor: 'minion',
        mainTitle: '“Миньоны”',
        addClass: '',
        image: ContentPath.MinionContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Веселые и озорные миньоны не могут без шалостей и шуток, находясь в лаборатории доктора Грю они
                        то и дело придумывают что-то новенькое. Веселая игровая программа, которая точно не оставит вас
                        равнодушными и заставит смеяться до слез.
                    </p>
                    <ul className="my-4">
                        <li> - Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Веселые задания от миньонов ;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Танцы-повторялки;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 3+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.MinionContent.contentItem.images,
            videos: ContentPath.MinionContent.contentItem.videos,
        }
    },
    {
        anchor: 'sonic',
        mainTitle: '“Соник”',
        addClass: '',
        image: ContentPath.SonicContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Самый быстрый, энергичный и сильный Ёж во всей вселенной. Он найдет самый уникальный способ
                        чтобы не только победить суперзлодея, но и сделать ваш праздник незабываемым.
                    </p>
                    <ul className="my-4">
                        <li> - Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Задания от Соника;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Веселые танцы;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 5+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.SonicContent.contentItem.images,
            videos: ContentPath.SonicContent.contentItem.videos,
        }
    },
    {
        anchor: 'tri_kota',
        mainTitle: '“Три кота”',
        addClass: '',
        image: ContentPath.Tri_kotaContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Два кота и одна маленькая кошечка. Коржик, Карамельки и Компот веселые и замечательные коты,
                        которые помогут сделать праздник веселым и ярким.

                    </p>
                    <ul className="my-4">
                        <li> - Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Вкусные задания от котов;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Веселые танцы;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 2+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.Tri_kotaContent.contentItem.images,
            videos: ContentPath.Tri_kotaContent.contentItem.videos,
        }
    },
    {
        anchor: 'among_us',
        mainTitle: '“Амонг Ас”',
        addClass: '',
        image: ContentPath.Among_usContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Где-то рядом притаился злодей, который хочет всех уничтожить. Герои Амонг Ас пытаются выяснить
                        кто из них является этим самым злодеем, но есть одна загвоздка, он один из них. Кто же предатель
                        и смогут ли ребята его раскрыть?

                    </p>
                    <ul className="my-4">
                        <li> - Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Головоломки;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Веселые танцы;</li>
                        <li>- Поиск предателя;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 5+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.Among_usContent.contentItem.images,
            videos: ContentPath.Among_usContent.contentItem.videos,
        }
    },
    {
        anchor: 'unicorn',
        mainTitle: ' “Пони”',
        addClass: '',
        image: ContentPath.UnicornContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>Прекрасные, веселые и забавные пони разбавят ваш праздник яркими красками и эмоциями. Море смеха,
                        веселья, юмора и элегантности доставят вашим детям незабываемые эмоции и впечатления.

                    </p>
                    <ul className="my-4">
                        <li> - Знакомства и приветствие;</li>
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Вкусные задания пони;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Веселые танцы;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фото на память</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 2+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.UnicornContent.contentItem.images,
            videos: ContentPath.UnicornContent.contentItem.videos,
        }
    },
    {
        anchor: 'addam_1',
        mainTitle: 'Мини-квест “Уэнсдей”',
        addClass: '',
        image: ContentPath.Addam_1Content.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Школа Нэвермор находится под угрозой, кто-то хочет отравить всех ядом “Беладонны”. Отважная,
                        строгая и расчетливая Уэнсдей не колеблясь ни на минуту отправляется на ее спасение, но для
                        этого ей нужна помощь ребят, так как путешествие полной тайн, загадок и жутких монстров, который
                        будут встречаться на пути.

                    </p>
                    <ul className="my-4">
                        <li>- Знакомство со школой и кланами;</li>
                        <li>- Таинственная карта Невермора;</li>
                        <li>- Пещера монстра;</li>
                        <li>- Знаменитый танец;</li>
                        <li>- Загадочный черный ящик;</li>
                        <li>- Специальный тематический реквизит;</li>
                        <li>- Шифр от Вещи;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Жуткая фотосессия;</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 5+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.Addam_1Content.contentItem.images,
            videos: ContentPath.Addam_1Content.contentItem.videos,
        }
    },
    {
        anchor: 'yensday',
        mainTitle: 'Мини-квест “Уэнсдей и Энид”',
        addClass: '',
        image: ContentPath.YensdayContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Школа Нэвермор находится под угрозой, кто-то хочет отравить всех ядом “Беладонны”, а также Энид
                        нашла часть пророчества на котором изображена Уэнсдей и чтобы его разгадать нужно собрать его
                        целиком.
                    </p>
                    <p>
                        Ребята вместе со строгой Уэнсдей и озорной Энид отправятся в школу Невермор чтобы разгадать
                        секреты и тайны и загадки, найти яд и разгадать секрет пророчества.
                    </p>
                    <ul className="my-4">
                        <li>- Знакомство со школой и кланами;</li>
                        <li>- Таинственная карта Невермора;</li>
                        <li>- Пещера монстра;</li>
                        <li>- Знаменитый танец;</li>
                        <li>- Загадочный черный ящик;</li>
                        <li>- Специальный тематический реквизит;</li>
                        <li>- Задания от Энид;</li>
                        <li>- Огненные мыльные пузыри;</li>
                        <li>- Шифр от Вещи;</li>
                        <li>- Красочные фигурные шарики каждому от Энид;</li>
                        <li>- Черный гель-глиттер от Уэнсдей;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Жуткая фотосессия;</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 5+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.YensdayContent.contentItem.images,
            videos: ContentPath.YensdayContent.contentItem.videos,
        }
    },
    {
        anchor: 'prostokvashino',
        mainTitle: ' Игровой мини-спектакль “Простоквашино”',
        addClass: '',
        image: ContentPath.ProstokvashinoContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        В Простоквашино все течет своим чередом. Дядя Федор, кот Матроскин и Шарик занимаются своими
                        домашними делами, иногда к ним приезжают Мама и Папа. Кукушонок все также не пускает Печкина на
                        порог.
                    </p>
                    <p>В один из таких дней в Простоквашино приезжают гости и выясняется что не просто так… </p>
                    <ul className="my-4">
                        <li>- Продуманный сценарий</li>
                        <li>- Тематический реквизит</li>
                        <li>- Музыкальное оформление</li>
                        <li>- Игры с котом матроскиным</li>
                        <li>- Фотоохота от Шарика</li>
                        <li>- 2 аниматора: Дядя Федор и почтальон Печкин</li>
                        <li>- Вынос тортика</li>
                        <li>- фотосессия с героями</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 4+</li>

                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.ProstokvashinoContent.contentItem.images,
            videos: ContentPath.ProstokvashinoContent.contentItem.videos,
        }
    },
    {
        anchor: 'pirates',
        mainTitle: 'Квест “Затерянные сокровища”',
        addClass: '',
        image: ContentPath.PiratesContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                        Капитан Рык и его верная спутница Марго отправляются на поиски потерянных сокровищ на край
                        света, которые до этого никто так и не смог найти. Приключение обещает быть очень опасным и
                        захватывающим, но для этого им нужна команда….
                    </p>
                    <ul className="my-4">
                        <li>- Карта сокровищ;</li>
                        <li>- Пиратские испытание;</li>
                        <li>- Специальный реквизит;</li>
                        <li>- Продуманный сценарий;</li>
                        <li>- Посвящение в пираты;</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Головоломки;</li>
                        <li>- Сундук с золотом (шоколадные монетки для всех);</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фотосессия на память .</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 6+</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.PiratesContent.contentItem.images,
            videos: ContentPath.PiratesContent.contentItem.videos,
        }
    },
    {
        anchor: 'harrypotter',
        mainTitle: 'Гарри Поттер',
        addClass: '',
        image: ContentPath.HarryPotterContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                    Самый известный волшебник. Буду маленьким мальчиком пережил нападение самого злого и темного мага, что дало ему не только небывалую силу, но и известность. Пройдя школу магии волшебства и чародейства "Хогвартс", а также находясь под покровом и обучением самого Дамблдора,  найдя там верных друзей и союзником смог одолеть Волан Де Морта и освободить мир волшебников от зла.
                    </p>
                    <ul className="my-4">
                        <li>- Поздравление именинника (цы);</li>
                        <li>- Распределение по факультетам;</li>
                        <li>- Фокусы;</li>
                        <li>- Головоломки;</li>
                        <li>- Задания и испытания;</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Танцы-повторялки;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фотосессия на память .</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 3+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.HarryPotterContent.contentItem.images,
            videos: ContentPath.HarryPotterContent.contentItem.videos,
        }
    },
    {
        anchor: 'ledybag',
        mainTitle: 'Леди Баг и Супер-Кот',
        addClass: '',
        image: ContentPath.LedyBagContent.contentItem.titleImage,
        pageContent: {
            title: '',
            description:
                <>
                    <p>
                    Действие разворачивается на улицах Парижа в наши дни. В обыкновенной французской школе учатся девочка по имени Маринетт Дюпен-Чен и её одноклассник Адриан Агрест, в которого она влюблена. Казалось бы, классическая история первой любви, но…
                    Эти ребята — совсем не те, за кого себя выдают. Когда городу угрожает опасность, Маринетт превращается в супергероиню Леди Баг, а Адриан — в Супер-Кота. Их невероятные способности помогают друзьям бороться со злом в Париже, но при этом никто из них не знает, кто на самом деле скрывается под маской. И только объединившись, супергерои смогут одолеть их двух общих заклятых врагов — Бражника, который рассылает по городу акумы — тёмных бабочек , превращающих обыкновенных жителей в порабощённых злодеев, и Маюру, которая рассылает по городу тёмные перья, создающие помощников для злодеев.
                    </p>
                    <ul className="my-4">
                    <li>- Поздравление именинника (цы);</li>
                        <li>- Распределение по факультетам;</li>
                        <li>- Супергеройские задания;</li>
                        <li>- Музыкальное сопровождение;</li>
                        <li>- Подвижные конкурсы и игры;</li>
                        <li>- Яркий тематический реквизит;</li>
                        <li>- Танцы-повторялки;</li>
                        <li>- Вынос тортика;</li>
                        <li>- Фотосессия на память .</li>
                    </ul>
                    <ul>
                        <li> Программа на возраст от 3+</li>
                        <li> Программа рассчитана от 10 детей на 1 аниматора.</li>
                        <li> Программу можно разнообразить мыльными пузырями, серебряной дискотекой, шариками,
                            фотографом и аквагримом.
                        </li>
                    </ul>
                </>
            ,
            images: ContentPath.LedyBagContent.contentItem.images,
            videos: ContentPath.LedyBagContent.contentItem.videos,
        }
    },
]