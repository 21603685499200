import React from 'react';
import {Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {ImagePreloader} from "../../../shared/images/imagePreloader";
import {ComponentLayout} from "../../../shared/section-layout/UI/component-layout";
import {SimpleTitle, titleAlign} from "../../../shared/titles/simpleTitle";
import {GalleryItem2} from "./items/gallery-item-2";

import bgImage from "../../../assets/images/bg/SL_050721_42770_15.jpg";

interface GalleryProps {
    children?: string | JSX.Element | JSX.Element[]
    data: any
}

export const GalleryType2: React.FC<GalleryProps> = props => {
    return (
        <>
            <style>
                {':root {' +
                    '    --swiper-theme-color: orange;' +
                    '}' +
                    '.swiper-button-next, .swiper-button-prev {' +
                    'background: white;' +
                    'border-radius: 100%;' +
                    'padding: 2rem' +
                    '}' +
                    '.swiper-button-prev::after, .swiper-button-next::after{' +
                    'font-size: 25px;' +
                    '}' +
                    ''}
            </style>
            <ComponentLayout bgImage={bgImage}>
                <SimpleTitle
                    title={'Фотоотчеты'}
                    description={'Вы можете увидеть, как рады дети и как довольны их родители. И мы хотели бы увдеть на этих фотографиях и вас'}
                    titleAlign={titleAlign.center}
                />
                <div className="w-full gap-4 hidden lg:flex">
                    <Swiper
                        loop={true}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className={'w-full h-fit '}
                        spaceBetween={50}
                        slidesPerView={3}
                    >
                        {props.data.map((el: string, index: any) => {

                            return <SwiperSlide key={index}>
                                <GalleryItem2>
                                    <ImagePreloader image={el}/>
                                </GalleryItem2>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
                <div className="w-full flex gap-4 lg:hidden">
                    <Swiper
                        loop={true}
                        modules={[Pagination, Navigation]}
                        className={'w-full h-fit'}
                        spaceBetween={20}
                        slidesPerView={1}
                    >
                        {props.data.map((el: string, index: any) => {

                            return <SwiperSlide key={index}>
                                <GalleryItem2>
                                    <ImagePreloader image={el}/>
                                </GalleryItem2>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
            </ComponentLayout>
        </>
    )
};