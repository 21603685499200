export const listenToScroll = (setIsVisible: any) => {
    let heightToHideFrom = 100;
    const winScroll = document.body.scrollTop ||
        document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
        setTimeout(() => {
            setIsVisible(false);
        }, 100)
    } else {
        setTimeout(() => {
            setIsVisible(true);
        }, 100)
    }
};