import React from 'react';

interface WorktimeProps {
    children?: string | JSX.Element | JSX.Element[]
}

export const Worktime: React.FC<WorktimeProps> = props => {
    return (
        <>
            <p className={'align-middle m-auto text-xs lg:text-base'}>С 8:00 до 22:00 без выходных</p>
        </>
    );
};