import { ContentPath } from "../../../shared/contetntPath/ContentPath";
import { iProgramsDataItem } from "../types/types";
//
export const AdditionalServicesData: iProgramsDataItem[] = [
	{
		anchor: "photo",
		mainTitle: "«Фотограф»",
		addClass: "",
		image: ContentPath.PhotographerContent.contentItem.titleImage,
		pageContent: {
			title: "«Фотограф»",
			description: (
				<>
					<p>
						Фотограф - если вы хотите запечатлеть свой праздник,
						чтобы он надолго остался в вашей памяти и вы в любой
						момент могли открыть фотоальбом и вспомнить эти яркие
						моменты, вы можете воспользоваться услугами фотографа. У
						нас есть как бюджетные так и более дорогие варианты.
					</p>
				</>
			),
			images: ContentPath.PhotographerContent.contentItem.images,
			videos: ContentPath.PhotographerContent.contentItem.videos,
		},
	},
	{
		anchor: "aqua",
		mainTitle: "«Аквагрим»",
		addClass: "",
		image: ContentPath.Akvagrim1Content.contentItem.titleImage,
		pageContent: {
			title: "«Аквагрим»",
			description: (
				<>
					<p>
						Аквагрим - ваш ребенок мечтает стать человеком пауком,
						бэтменом или прекрасной принцессой? А может вы и сами
						желаете превратиться в милую кошечку или опасного льва?
						Это все возможно благодаря аквагриму. Профессиональные
						гримеры сделают из вас любого, кого вы сами и ваши детки
						пожелаете. Гримм абсолютно безопасен и легко смывается
						водой, что делает его одним из самых желанных атрибутов
						на празднике.
					</p>
				</>
			),
			images: ContentPath.Akvagrim1Content.contentItem.images,
			videos: ContentPath.Akvagrim1Content.contentItem.videos,
		},
	},
	{
		anchor: "baloon",
		mainTitle: "«Фигурные шарики»",
		addClass: "",
		image: ContentPath.Air_balloons1Content.contentItem.titleImage,
		pageContent: {
			title: "«Фигурные шарики»",
			description: (
				<>
					<p>
						Фигурные шарики - самый важный атрибут любого праздника.
						Когда мы слышим слово “праздник” первое что мы
						представляем это шарики. Фигурные шарики - это шарики из
						которых можно сделать собачку, цветок, мышку, шапку и
						многое другое. Это один из самых милых и замечательных
						подарков на вашем торжестве.
					</p>
				</>
			),
			images: ContentPath.Air_balloons1Content.contentItem.images,
			videos: ContentPath.Air_balloons1Content.contentItem.videos,
		},
	},
];
