import show_silver1 from '../../../assets/images/gallery/_show_silver/show_silver1.jpg'
import show_silver2 from '../../../assets/images/gallery/_show_silver/show_silver2.jpg'
import show_silver3 from '../../../assets/images/gallery/_show_silver/show_silver3.jpg'
import show_silver4 from '../../../assets/images/gallery/_show_silver/show_silver4.jpg'
import show_silver5 from '../../../assets/images/gallery/_show_silver/show_silver5.jpg'
import show_silver6 from '../../../assets/images/gallery/_show_silver/show_silver6.jpg'
import show_silver7 from '../../../assets/images/gallery/_show_silver/show_silver7.jpg'
import {iContentPathItem} from "../types/type";
import silverShow from '../../../assets/images/masterclass/silverShow-min.jpg'

export const Show_silver1Content: iContentPathItem = {
    titleImage: silverShow,
    titleVideo: '',
    images: [
        {src: show_silver1},
        {src: show_silver2},
        {src: show_silver3},
        {src: show_silver4},
        {src: show_silver5},
        {src: show_silver6},
        {src: show_silver7},
    ],
    videos: []
}