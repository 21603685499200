import React from 'react';
import {Route, Routes} from 'react-router';
import {AdditionalServicesPage} from "../../pages/AdditionServices-page/UI/AdditionalServices-page";
import {AnimationPage} from "../../pages/animation-page/UI/animation-page";
import {MainPage} from "../../pages/main/UI/main-page";
import {ShowMasterclassPage} from "../../pages/show-masterclass-page/UI/show-masterclass-page";
import {ShowmanPage} from "../../pages/showman-page/UI/showman-page";
import '../../processes/App.css';
import {Layout} from "./layout";
import NotFound from './notfound';
import NewYearPage from '../../pages/newYear/NewYearPage';


function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<MainPage/>}/>
                <Route path={'animation/:id'} element={<AnimationPage/>}/>
                <Route path={'masterclass_show/:id'} element={<ShowMasterclassPage/>}/>
                <Route path={'showman/:id'} element={<ShowmanPage/>}/>
                <Route path={'add/:id'} element={<AdditionalServicesPage />} />
                <Route path={'newYear'} element={<NewYearPage/>}/>
                <Route path='*' element={<NotFound />}/>
            </Route>
        </Routes>
    );
}

export default App;
