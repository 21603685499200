import minions1 from '../../../assets/images/gallery/minion/minion1.jpg'
import minions2 from '../../../assets/images/gallery/minion/minion2.jpg'
import minions3 from '../../../assets/images/gallery/minion/minion3.jpg'
import minions4 from '../../../assets/images/gallery/minion/minion4.jpg'
import minionImage from '../../../assets/images/animations/min/minione-min.jpg'
import {iContentPathItem} from "../types/type";

export const MinionContent: iContentPathItem = {
    titleImage: minionImage,
    titleVideo: '',
    images: [
        {src: minions1},
        {src: minions2},
        {src: minions3},
        {src: minions4},
    ],
    videos: []
}