import { About } from "../../entities/About/UI/About";
import { GalleryType1 } from "../../entities/Gallery/UI/Gallery-type-1";
import { GalleryType2 } from "../../entities/Gallery/UI/Gallery-type-2";
import { GalleryType3 } from "../../entities/Gallery/UI/Gallery-type-3";
import { GalleryData } from "../../entities/Gallery/data/data";
import { WhereWeWork } from "../../entities/WhereWeWork/UI/WhereWeWork";
import { FeaturesApi } from "../../entities/features/API/features-api";
import { FeaturesType1 } from "../../entities/features/UI/features-type-1";
import { FeaturesType2 } from "../../entities/features/UI/features-type-2";
import { featuresAlign } from "../../entities/features/interface/unterface";
import { HeroAPI } from "../../entities/hero/API/hero-api";
import { Hero } from "../../entities/hero/UI/hero";
import { NewYear } from "../../entities/newYear/newYear";
import { ProductsGridType1 } from "../../entities/product-grid/UI/products-grid-type-1";
import { ProductsGridType2 } from "../../entities/product-grid/UI/products-grid-type-2";
import { ProductsGridType3 } from "../../entities/product-grid/UI/products-grid-type-3";
import { ProductData } from "../../entities/product-grid/data/data";
import { Steps } from "../../entities/steps/UI/steps";
import { ComponentLayout } from "../../shared/section-layout/UI/component-layout";

export interface iLandingStructure {
	name: string;
	anchor: string;
	component: string | JSX.Element | JSX.Element[];
	addToNavbar: boolean;
}

export const LandingStructure: iLandingStructure[] = [
	{
		name: "Главная",
		anchor: "home",
		component: <Hero data={HeroAPI} />,
		addToNavbar: true,
	},
	{
		name: "Новый год",
		anchor: "NewYear",
		component: <NewYear data={HeroAPI} />,
		addToNavbar: false,
	},
	{
		name: "О нас",
		anchor: "about_us",
		component: (
			<FeaturesType1
				align={featuresAlign.Right}
				data={FeaturesApi.data.features_1}
			/>
		),
		addToNavbar: true,
	},
	{
		name: "Преимущества",
		anchor: "features",
		component: (
			<FeaturesType2
				align={featuresAlign.Left}
				data={FeaturesApi.data.features_2}
			/>
		),
		addToNavbar: true,
	},
	{
		name: "Услуги",
		anchor: "services",
		component: <ProductsGridType2 data={ProductData.ShowToYouCelebrity} />,
		addToNavbar: true,
	},

	{
		name: "Анимационные программы",
		anchor: "service-animations",
		component: (
			<ProductsGridType3
				data={ProductData.AnimationPrograms}
				isLinked={true}
				linkName={"animation"}
			/>
		),
		addToNavbar: false,
	},
	{
		name: "Шоу и мастерклассы:",
		anchor: "service-shows",
		component: (
			<ProductsGridType3
				data={ProductData.Masterclass}
				isLinked={true}
				linkName={"masterclass_show"}
			/>
		),
		addToNavbar: false,
	},
	{
		name: "Ведущий",
		anchor: "service-leading",
		component: (
			<ProductsGridType3
				data={ProductData.Showman}
				isLinked={true}
				linkName={"showman"}
			/>
		),
		addToNavbar: false,
	},
	{
		name: "Доп. услуги",
		anchor: "any-service",
		component: (
			<ComponentLayout>
				<ProductsGridType1
					data={ProductData.AdditionServices}
					isLinked={true}
					linkName={"add"}
				/>
			</ComponentLayout>
		),
		addToNavbar: true,
	},
	{
		name: "Где работаем",
		anchor: "work_location",
		component: <WhereWeWork data={""} />,
		addToNavbar: true,
	},
	{
		name: "Видеоотчеты",
		anchor: "videos",
		component: <GalleryType1 data={""} />,
		addToNavbar: true,
	},
	{
		name: "Фотоотчеты",
		anchor: "photos",
		component: <GalleryType2 data={GalleryData.photoReports} />,
		addToNavbar: true,
	},
	{
		name: "Отзывы",
		anchor: "reviews",
		component: <GalleryType3 data={GalleryData.reviews} />,
		addToNavbar: true,
	},
	{
		name: "Этапы",
		anchor: "Steps",
		component: <Steps data={""} />,
		addToNavbar: true,
	},
	{
		name: "About",
		anchor: "About",
		component: <About data={""} />,
		addToNavbar: false,
	},
];
