import uensday_endi1 from '../../../assets/images/gallery/uensday_endi/uensday_endi1.jpg'
import uensday_endi2 from '../../../assets/images/gallery/uensday_endi/uensday_endi2.jpg'
import uensday_endi3 from '../../../assets/images/gallery/uensday_endi/uensday_endi3.jpg'
import uensday_endi4 from '../../../assets/images/gallery/uensday_endi/uensday_endi4.jpg'
import uensday_endi5 from '../../../assets/images/gallery/uensday_endi/uensday_endi5.jpg'
import uensday_endi6 from '../../../assets/images/gallery/uensday_endi/uensday_endi6.jpg'
import uensday_endi7 from '../../../assets/images/gallery/uensday_endi/uensday_endi7.jpg'
import uensday_endi_v1 from '../../../assets/images/gallery/uensday_endi/uensday_endi_v1.mp4'
import {iContentPathItem} from "../types/type";
import yensdayImage from '../../../assets/images/animations/min/yensday-min.jpg'
export const YensdayContent: iContentPathItem = {
    titleImage: yensdayImage,
    titleVideo: '',
    images: [
        {src: uensday_endi1},
        {src: uensday_endi2},
        {src: uensday_endi3},
        {src: uensday_endi4},
        {src: uensday_endi5},
        {src: uensday_endi6},
        {src: uensday_endi7},
    ],
    videos: [
        {src: uensday_endi_v1},
    ]
}