import React from 'react';
import {Outlet} from "react-router";
import {ScrollOnTop} from "../../features/scroll-on-top/UI/scrollOnTop";
import {Footer} from "../../widgets/footer/UI/footer";
import {Header} from '../../widgets/header/UI/header';


interface LayoutProps {

}

export const Layout: React.FC<LayoutProps> = () => {
    return (
        <>
            <div className={'flex flex-col'}>
                <Header/>
                <main className="main h-full flex shrink py-6">
                    <Outlet/>
                </main>
                <Footer/>
                <ScrollOnTop/>
            </div>
        </>
    );
};