import slime from '../../../assets/images/masterclass/slime-min.jpg'

import slime_v from '../../../assets/images/gallery/__slime/slime_v1.mp4'

import {iContentPathItem} from "../types/type";

export const SlimeContent: iContentPathItem = {
    titleImage: slime,
    titleVideo: '',
    images: [{src: slime}],
    videos: [{src: slime_v},]
}