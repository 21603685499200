import leading1 from '../../../assets/images/gallery/_leading/leading1.jpg'
import leading2 from '../../../assets/images/gallery/_leading/leading2.jpg'
import leading3 from '../../../assets/images/gallery/_leading/leading2.jpg'
import leading_v1 from '../../../assets/images/gallery/_leading/leading_v1.mp4'
import {iContentPathItem} from "../types/type";
import showman from '../../../assets/images/showman/showman-min.jpg'
export const Leading1Content: iContentPathItem = {
    titleImage: showman,
    titleVideo: '',
    images: [
        {src: leading1},
        {src: leading2},
        {src: leading3},
    ],
    videos: [
        {src: leading_v1},
    ]
}