import React, {useRef} from 'react';
import {Link} from "react-router-dom";
import {useHover} from "../../../../processes/hooks/useHover";

interface ProductItemProps {
    children?: string | JSX.Element | JSX.Element[]
    addClass?: string
    mainTitle: string
}

export const ProductItem2: React.FC<ProductItemProps> = props => {
    const ref = useRef(null);
    const isHovering = useHover(ref)
    return (
        <>
            <div
                ref={ref}
                className={(props.addClass || '') + " shrink bg-gray-200 flex flex-col h-96 rounded-lg overflow-hidden hover:cursor-pointer"}
            >
                <div className={'relative h-full overflow-hidden'}>
                    <div className={"hover:scale-125 w-full h-full duration-300"}>
                        {props.children}
                    </div>
                </div>
                {
                    !isHovering
                        ?
                        <h4 className={'w-full h-1/4 items-center justify-center flex py-4 text-center bg-green-200 duration-150'}>{props.mainTitle || 'mainTitle'}</h4>
                        :
                        <h4 className={'w-full h-1/4 items-center justify-center flex py-4 text-center bg-sky-200 duration-150'}>{'подробнее'}</h4>
                }
            </div>
        </>
    );
};