import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ImagePreloader } from "../../shared/images/imagePreloader";
import { NewYearData } from "./NewYearData";
import { iNewYearData } from "./types";

type NewYearPageProps = {};

const NewYearPage: FC<NewYearPageProps> = (props) => {
	const { id } = useParams();
	//
	const [data, setData] = useState<iNewYearData>();
	//
	useEffect(() => {
		setData((el: any) => {
			return NewYearData.filter((el) => el.anchor === "newYear")[0];
		});
	}, [id]);
	//

	return (
		<>
			<section className='w-screen min-h-[calc(100vh)] py-64 flex flex-col '>
				<div className='container mx-auto gap-32 flex items-center flex-col'>
					<div className='space-y-6 text-center'>
						<h1 className={"text-3xl"}>
							{data?.pageContent.title}
						</h1>
						<p>{data?.pageContent.description}</p>
					</div>
					<div className=''>
						<ul
							className={
								"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-center px-2"
							}
						>
							{data?.pageContent.images.map((el: any) => {
								return (
									<li
										className={
											"w-auto h-96 overflow-hidden rounded-xl"
										}
									>
										<ImagePreloader image={el.src} />
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</section>
		</>
	);
};

export default NewYearPage;
