import React, {useContext} from 'react';
import {ComponentContext} from "../../processes/context";

export enum titleAlign {
    start = ' text-start self-start',
    center = 'text-center self-center',
    end = 'text-end self-end'
}

interface SimpleTitleProps {
    children?: string | JSX.Element | JSX.Element[]
    title: string
    description: string
    titleAlign: titleAlign
}

export const SimpleTitle: React.FC<SimpleTitleProps> = props => {
    const {anchor} = useContext(ComponentContext)
    return (
        <>
            <div id={anchor} className={"SimpleTitle " + props.titleAlign + ' lg:w-2/3 flex flex-col gap-6'}>
                {props.title ? <h2 className={'text-2xl lg:text-4xl font-bold z-10'}>{props.title || ''}</h2> : null}
                {props.description ? <p>{props.description || ''}</p> : null}
            </div>
        </>
    );
};