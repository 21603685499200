import {iContentPathItem} from "../types/type";

import HarryPotterImage4 from '../../../assets/images/gallery/harry_potter/harry_potter4.jpg'

import HarryPotterImage1 from '../../../assets/images/gallery/harry_potter/harry_potter1.jpg'
import HarryPotterImage2 from '../../../assets/images/gallery/harry_potter/harry_potter2.jpg'
import HarryPotterImage3 from '../../../assets/images/gallery/harry_potter/harry_potter3.jpg'

export const HarryPotterContent: iContentPathItem = {
    titleImage: HarryPotterImage4,
    titleVideo: '',
    images: [
        {src: HarryPotterImage1},
        {src: HarryPotterImage2},
        {src: HarryPotterImage3},
    ],
    videos: [
        
    ]
}