import { Addam_1Content } from "./content/addam_1-Content";
import { Air_balloons1Content } from "./content/air_balloons1-Content";
import { Akvagrim1Content } from "./content/akvagrim1-Content";
import { Among_usContent } from "./content/among_us-Content";
import { BarbyContent } from "./content/barby";
import { DogspatruleContent } from "./content/dogspatrule-Content";
import { ElsaContent } from "./content/elsa-Content";
import { HarryPotterContent } from "./content/harryPotter-Content";
import { Leading1Content } from "./content/leading1-Content";
import { LedyBagContent } from "./content/ledyBag-Content";
import { Mabel_n_deeperContent } from "./content/mabel_n_deeper-Content";
import { Mikki_maisContent } from "./content/mikki_mais-Content";
import { MinionContent } from "./content/minion-Content";
import { NewYearContent } from "./content/newYear-Content";
import { PhotographerContent } from "./content/photographer-Content";
import { PiratesContent } from "./content/pirates-Content";
import { ProstokvashinoContent } from "./content/prostokvashino-Content";
import { Show_bubbles1Content } from "./content/show_bubbles1-Content";
import { Show_silver1Content } from "./content/show_silver1-Content";
import { SlimeContent } from "./content/slime-Content";
import { SonicContent } from "./content/sonic-Content";
import { SpidermanContent } from "./content/spiderman-Content";
import { SuperfamalyContent } from "./content/superfamaly-Content";
import { Tri_kotaContent } from "./content/tri_kota-Content";
import { UnicornContent } from "./content/unicorn-Content";
import { XagiContent } from "./content/xagi-Content";
import { YensdayContent } from "./content/yensday-Content";
import { iContentPath } from "./types/type";

export const ContentPath: iContentPath = {
	// новый год
	NewYear: {
		contentItem: NewYearContent,
	},
	// щенячий патруль
	Dogspatrule: {
		contentItem: DogspatruleContent,
	},
	// хаги ваги
	XagiContent: {
		contentItem: XagiContent,
	},
	// микки
	Mikki_maisContent: {
		contentItem: Mikki_maisContent,
	},
	// спайдермэн
	SpidermanContent: {
		contentItem: SpidermanContent,
	},
	// суперсемейка
	SuperfamalyContent: {
		contentItem: SuperfamalyContent,
	},
	// графити фолз
	Mabel_n_deeperContent: {
		contentItem: Mabel_n_deeperContent,
	},
	// эльза
	ElsaContent: {
		contentItem: ElsaContent,
	},
	// миньоны
	MinionContent: {
		contentItem: MinionContent,
	},
	// соник
	SonicContent: {
		contentItem: SonicContent,
	},
	// три кота
	Tri_kotaContent: {
		contentItem: Tri_kotaContent,
	},
	// амонг ас
	Among_usContent: {
		contentItem: Among_usContent,
	},
	// единорог
	UnicornContent: {
		contentItem: UnicornContent,
	},
	// уэнсдей
	Addam_1Content: {
		contentItem: Addam_1Content,
	},
	// уэнсдей и эшли
	YensdayContent: {
		contentItem: YensdayContent,
	},
	// простоквашино
	ProstokvashinoContent: {
		contentItem: ProstokvashinoContent,
	},
	// пираты карибского моря
	PiratesContent: {
		contentItem: PiratesContent,
	},
	// пираты карибского моря
	HarryPotterContent: {
		contentItem: HarryPotterContent,
	},
	// пираты карибского моря
	LedyBagContent: {
		contentItem: LedyBagContent,
	},
	// Барби
	BarbyContent: {
		contentItem: BarbyContent,
	},
	// серебряное шоу
	Show_silver1Content: {
		contentItem: Show_silver1Content,
	},
	// шоу гигантских мыльных пузырей
	Show_bubbles1Content: {
		contentItem: Show_bubbles1Content,
	},
	// ведущий
	Leading1Content: {
		contentItem: Leading1Content,
	},
	// аквагрим
	Akvagrim1Content: {
		contentItem: Akvagrim1Content,
	},
	// шарики
	Air_balloons1Content: {
		contentItem: Air_balloons1Content,
	},
	// мастер класс по слаймам
	SlimeContent: {
		contentItem: SlimeContent,
	},
	// фотограф
	PhotographerContent: {
		contentItem: PhotographerContent,
	},
};
