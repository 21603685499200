import React from 'react';
import { NavItemProps } from './type';

export const NavItem: React.FC<NavItemProps> = ({anchor,name}) => {
    return (
        <>
            <li className={'hover:cursor-pointer lg:hover:scale-105 duration-150 text-lg lg:text-sm whitespace-nowrap py-1 px-2'}>
                <a className={''} href={'/#' + anchor}>{name}</a>
            </li>
        </>
    );
};