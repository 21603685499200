import React from 'react';

interface PreloaderProps {
    children?: string | JSX.Element | JSX.Element[]
    addClass?: string
    preloaderName: string
}

export const Preloader: React.FC<PreloaderProps> = props => {
    if (props.children) {
        return (
            <>
                {props.children}
            </>
        );
    } else {
        return (
            <>
                <div className={'w-full h-full flex items-center justify-center p-2 bg-sky-100'}>
                    <svg
                        className={'w-1/3 h-1/3 p-1'}
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                    >
                        <path d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm56-97h489L578-473 446-302l-93-127-117 152Zm-56 97v-600 600Z"/>
                    </svg>
                </div>
            </>
        );
    }
};