import show_bubbles1 from '../../../assets/images/gallery/_show_bubbles/show_bubbles1.jpg'
import show_bubbles2 from '../../../assets/images/gallery/_show_bubbles/show_bubbles2.jpg'
import show_bubbles3 from '../../../assets/images/gallery/_show_bubbles/show_bubbles3.jpg'
import show_bubbles4 from '../../../assets/images/gallery/_show_bubbles/show_bubbles4.jpg'
import show_bubbles5 from '../../../assets/images/gallery/_show_bubbles/show_bubbles5.jpg'
import show_bubbles6 from '../../../assets/images/gallery/_show_bubbles/show_bubbles6.jpg'
import show_bubbles7 from '../../../assets/images/gallery/_show_bubbles/show_bubbles7.jpg'
import show_bubbles8 from '../../../assets/images/gallery/_show_bubbles/show_bubbles8.jpg'
import show_bubbles9 from '../../../assets/images/gallery/_show_bubbles/show_bubbles9.jpg'
import {iContentPathItem} from "../types/type";
import bobbles from '../../../assets/images/masterclass/bobbles-min.jpg'
export const Show_bubbles1Content: iContentPathItem = {
    titleImage: bobbles,
    titleVideo: '',
    images: [
        {src: show_bubbles1},
        {src: show_bubbles2},
        {src: show_bubbles3},
        {src: show_bubbles4},
        {src: show_bubbles5},
        {src: show_bubbles6},
        {src: show_bubbles7},
        {src: show_bubbles8},
        {src: show_bubbles9},
    ],
    videos: [

    ]
}