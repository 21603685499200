//
import newYear_1 from "../../../assets/images/gallery/newYear/IMG_4673.png";
import newYear_2 from "../../../assets/images/gallery/newYear/IMG_4675.png";
import newYear_3 from "../../../assets/images/gallery/newYear/IMG_4785.jpeg";
//
import { iContentPathItem } from "../types/type";

export const NewYearContent: iContentPathItem = {
	titleImage: newYear_1,
	titleVideo: "",
	images: [{ src: newYear_1 }, { src: newYear_2 }, { src: newYear_3 }],
	videos: [],
};
