import React from 'react';


interface FeatureIconType2Props {
    children?: string | JSX.Element | JSX.Element[]
    iconSource?: string
}

export const FeatureIconType2: React.FC<FeatureIconType2Props> = ({iconSource}) => {
    return (
        <>

                <img className={'h-10 w-auto'} src={iconSource} alt="ic"/>

        </>
    );
};