import {iContentPathItem} from "../types/type";

import ledyBagImage from '../../../assets/images/gallery/ledy_bag/ledy_bag.jpg'
import ledyBag_v1 from "../../../assets/images/gallery/ledy_bag/ledy_bag_v1.mp4";

import ledyBagImage1 from '../../../assets/images/gallery/ledy_bag/ledy_bag1.jpg'
import ledyBagImage2 from '../../../assets/images/gallery/ledy_bag/ledy_bag2.jpg'
import ledyBagImage3 from '../../../assets/images/gallery/ledy_bag/ledy_bag3.jpg'

export const LedyBagContent: iContentPathItem = {
    titleImage: ledyBagImage,
    titleVideo: '',
    images: [
        {src: ledyBagImage1},
        {src: ledyBagImage2},
        {src: ledyBagImage3},
    ],
    videos: [
        {src: ledyBag_v1},
    ]
}