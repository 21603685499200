import {staticInfo} from "../../../app/API/staticInfo";
import {PhoneNumber} from "../../../shared/labels/phone-number/phone-number";
import {iHeroAPI} from "../inetrface/interface";

export const HeroAPI: iHeroAPI = {
    data: {
        title: 'Эвент Точка',
        description: 'ГАРАНТИРУЕМ ВЕСЕЛЫЙ ПРАЗДНИК ИЛИ МОЖЕТЕ НЕ ПЛАТИТЬ!',
        action: <PhoneNumber phone={staticInfo.phone[0]}/>
    },
}



