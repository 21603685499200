import React from 'react';
import Lottie from "lottie-react";
import {ComponentLayout} from "../../../shared/section-layout/UI/component-layout";
import {PhoneNumber} from '../../../shared/labels/phone-number/phone-number';
import {staticInfo} from "../../../app/API/staticInfo";
import {SimpleTitle, titleAlign} from "../../../shared/titles/simpleTitle";

import bgImage from "../../../assets/images/bg/party-birthday-banner-with-space-text.jpg";
import celebrationAnimation from "../../../assets/lottie/70203-colorful-balloons.json"

interface HeroProps {
    data: any

}

export const Hero: React.FC<HeroProps> = (props) => {
    return (
        <>
            <ComponentLayout>
                <SimpleTitle
                    title={''}
                    description={''}
                    titleAlign={titleAlign.center}
                />
                <div style={{ 
                        backgroundImage: `url(`+bgImage+`)` 
                        }}
                    className="lg:mt-0 mt-28 bg-cover bg-no-repeat bg-center rounded-[50px] flex flex-col gap-12 mx-auto py-32 w-full h-full justify-center items-center text-center bg-yellow-200">
                    <h1 className={'text-xl lg:text-5xl lg:w-1/2'}>{'Агентство по организации детских праздников в РБ'}</h1>
                    <p>
                        {'ГАРАНТИРУЕМ ВЕСЕЛЫЙ ПРАЗДНИК'}
                        <br/>
                        {'для детей любого возраста'}
                    </p>
                    <PhoneNumber phone={staticInfo.phone[0]}/>

                </div>
                <div className='absolute -left-32 bottom-0 rotate-45 w-64 lg:w-96'>
                    <Lottie animationData={celebrationAnimation} />
                </div>
            </ComponentLayout>
        </>
    );
};