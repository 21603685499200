import textIcon from "../../../assets/icons/text.svg";
import timeIcon from "../../../assets/icons/time.svg";
import plusIcon from "../../../assets/icons/plus.png";
import CTRImage from "../../../assets/content/cta_description.png";
import FeaturesImage from "../../../assets/content/feautures_ex.jpg"
import {featureIconColor} from "../../../shared/icons/feature-icon-type1";



import PartyImage from "../../../assets/images/bg/SL_050721_42770_15.jpg";

export const FeaturesApi = {
    data: {
        features_1:
            {
                title: 'title',
                mainImage: CTRImage,
                bgImage: '',
                content: [
                    {
                        iconColor: featureIconColor.blue,
                        iconSource: timeIcon,
                        title: 'Опыт работы с детьми > 12 лет',
                        description: 'Мы тщательно отбирали всех членов нашей команды и тренировали их, чтобы развлекать ваших детей',
                    },
                    {
                        iconColor: featureIconColor.red,
                        iconSource: timeIcon,
                        title: 'Эксклюзивные программы',
                        description: 'Работем по всей Республике Беларусь. Организовываем праздники в садиках, офисах и на природе.'
                    },
                    {
                        iconColor: featureIconColor.green,
                        iconSource: textIcon,
                        title: '500+ проведенных мероприятий',
                        description: 'Подбираем индивидуальные программы для вашего праздника.'
                    },
                    {
                        iconColor: featureIconColor.yellow,
                        iconSource: timeIcon,
                        title: 'Профессиональные актеры-аниматоры',
                        description: 'Профессиональные актеры-аниматоры, которые регулярно проходят обучение по повышению навыков '
                    },
                ]
            },
        features_2: {
            title: 'title_2',
            mainImage: FeaturesImage,
            bgImage: PartyImage,
            content: [
                {
                    iconSource: plusIcon,
                    description: 'Опыт работы с детьми более 12 лет'
                },
                {
                    iconSource: plusIcon,
                    description: 'Выезд в любую точку страны'
                },
                {
                    iconSource: plusIcon,
                    description: '500+ проведенных мероприятий'
                },
                {
                    iconSource: plusIcon,
                    description: 'Профессиональные актеры-аниматоры'
                },
                {
                    iconSource: plusIcon,
                    description: 'Эксклюзивные программы'
                },
                {
                    iconSource: plusIcon,
                    description: 'Красивые костюмы и уникальный реквизит ручной работы'
                },
                {
                    iconSource: plusIcon,
                    description: 'Возможность создания праздника по индивидуальному запросу'
                },
                {
                    iconSource: plusIcon,
                    description: 'Полное сопровождение вашего праздника от начала и до конца'
                },
            ]
        }
    }
}