import React from 'react';

export enum featureIconColor {
    blue = 'bg-sky-100',
    red = 'bg-rose-100',
    green = 'bg-emerald-100',
    yellow = 'bg-yellow-100'
}

interface FeatureIconType1Props {
    children?: string | JSX.Element | JSX.Element[]
    iconSource?: string
    iconColor?: featureIconColor
}

export const FeatureIconType1: React.FC<FeatureIconType1Props> = ({iconColor, iconSource}) => {
    return (
        <>
            <div className={iconColor + ' rounded-full p-8 w-fit h-auto absolute -z-10 left-[-10px] top-[-10px]'}>
                {/* <img src={iconSource} alt="ic"/> */}
            </div>
        </>
    );
};