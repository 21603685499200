import sokrovisha_v1 from "../../../assets/images/gallery/sokrovisha/sokrovisha_v1.mp4";
import {iContentPathItem} from "../types/type";

import piratesImage from '../../../assets/images/animations/min/pirates-min.jpg'

export const PiratesContent: iContentPathItem = {
    titleImage: piratesImage,
    titleVideo: '',
    images: [
        
    ],
    videos: [
        {src: sokrovisha_v1},
    ]
}