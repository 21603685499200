import React, { Suspense } from "react";
import { Preloader } from "../preloader/UI/preloader";

interface ImagePreloaderProps {
	children?: string | JSX.Element | JSX.Element[];
	image: string;
}

export const ImagePreloader: React.FC<ImagePreloaderProps> = (props) => {
	// const [src, setSrc] = useState<any>("");

	// function loadImage(image: string) {
	// 	let downloadingImage = new Image();
	// 	downloadingImage.src = image;
	// 	downloadingImage.onload = () => {
	// 		setSrc(image);
	// 	};
	// 	downloadingImage.onerror = () => {
	// 		console.error("image load error");
	// 	};
	// }

	// useEffect(() => {
	// 	loadImage(props.image);
	// }, [props.image]);

	return (
		<>
			<Suspense fallback={<Preloader preloaderName={"image"} />}>
				<img
					className={"object-cover rounded-xl w-full h-full"}
					src={props.image}
					alt={"gallery image of product"}
				/>
			</Suspense>

			{/* {src ? (
				<img
					className={"object-cover rounded-xl w-full h-full"}
					src={src}
					alt='image'
				/>
			) : (
				<Preloader preloaderName={"image"} />
			)} */}
		</>
	);
};
