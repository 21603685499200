import { FC } from "react";
import { Link } from "react-router-dom";

type moreButtonProps = {
	title: string
};

const MoreButton: FC<moreButtonProps> = ({title}) => {
	return (
		<>
			<Link to='newYear'
				className={
					"w-fit text-white hover:cursor-pointer rounded-full py-3 px-6 bg-gradient-to-r from-purple-500 to-blue-500 hover:scale-125 ease-in-out text-2xl"
				}
			>

				{title}

			</Link>
		</>
	);
};

export default MoreButton;
