import React from 'react';
import {StepsIcon} from "../../../shared/icons/steps-icon";

interface StepsItemProps {
    children?: string | JSX.Element | JSX.Element[]
    index: number
    title: string
}

export const StepsItem: React.FC<StepsItemProps> = props => {
    return (
        <>
            <div className={'flex flex-col gap-8 justify-center items-center lg:w-1/5 text-center'}>
                <StepsIcon index={props.index+1}/>
                <p>{props.title}</p>
            </div>
        </>
    );
};