import Lottie from "lottie-react";
import React from 'react';
import {Link} from "react-router-dom";

import bgImage from "../../../assets/images/bg/SL_050721_42770_15.jpg";

import celebrationAnimation from "../../../assets/lottie/98537-celebrate.json"
import {ImagePreloader} from "../../../shared/images/imagePreloader";
import {ComponentLayout} from "../../../shared/section-layout/UI/component-layout";
import {SimpleTitle, titleAlign} from "../../../shared/titles/simpleTitle";
import {ProductsGridProps} from '../types/types';
import {ProductItem2} from "./items/product-item-2";

interface ProductsGridType3Props extends ProductsGridProps {
    isLinked: boolean
    linkName:string
}

export const ProductsGridType3: React.FC<ProductsGridType3Props> = (props) => {

    return (
        <ComponentLayout bgImage={bgImage}>

            <SimpleTitle
                title={props.data.title}
                description={props.data.description}
                titleAlign={titleAlign.center}
            />
            <div className={"grid gap-4 w-full relative " + props.data.gridClass}>
                {props.data.animFlag || (
                    <>
                        <div className="absolute left-0 top-0 rotate-180">
                            <Lottie animationData={celebrationAnimation}/>
                        </div>
                        {/* <div className="absolute right-0 top-0 rotate-180">
                            <Lottie animationData={celebrationAnimation}/>
                        </div> */}
                    </>
                )}

                {props?.data?.content?.map((el: any, index,) => {
                    return (
                        el.mainTitle
                            ?
                            props.isLinked
                                ?
                                <Link to={props.linkName+'/'+el?.anchor}>
                                    <ProductItem2
                                        key={index}
                                        addClass={el.addClass}
                                        mainTitle={el.mainTitle}
                                    >
                                        <ImagePreloader image={el.image}/>
                                    </ProductItem2>
                                </Link>
                                :
                                <ProductItem2
                                    key={index}
                                    addClass={el.addClass}
                                    mainTitle={el.mainTitle}
                                >
                                    <ImagePreloader image={el.image}/>
                                </ProductItem2>
                            :
                            <div></div>
                    )
                })}
            </div>
        </ComponentLayout>
    )

};