import three_cats1 from '../../../assets/images/gallery/three_cats/three_cats1.jpg'
import three_cats2 from '../../../assets/images/gallery/three_cats/three_cats2.jpg'
import three_cats3 from '../../../assets/images/gallery/three_cats/three_cats3.jpg'
import three_cats4 from '../../../assets/images/gallery/three_cats/three_cats4.jpg'
import three_cats5 from '../../../assets/images/gallery/three_cats/three_cats5.jpg'
import three_cats_v1 from '../../../assets/images/gallery/three_cats/three_cats_v1.mp4'
import three_cats_v2 from '../../../assets/images/gallery/three_cats/three_cats_v2.mp4'
import {iContentPathItem} from "../types/type";
import tri_kotaImage from '../../../assets/images/animations/min/tri-kota-min.jpg'

export const Tri_kotaContent: iContentPathItem = {
    titleImage: tri_kotaImage,
    titleVideo: '',
    images: [
        {src: three_cats1},
        {src: three_cats2},
        {src: three_cats3},
        {src: three_cats4},
        {src: three_cats5},
    ],
    videos: [
        {src: three_cats_v1},
        {src: three_cats_v2},
    ]
}