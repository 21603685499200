import { FC } from "react";

type DropdownProps = {
	children: string | JSX.Element | JSX.Element[];
	title: string;
	anchor: string;
};

const Dropdown: FC<DropdownProps> = ({ children, title, anchor }) => {
	return (
		<>
			<div className='group relative px-2'>
				<div className='gap-2 items-center hidden lg:flex cursor-pointer'>
					<a href={"/#" + anchor} className='text-lg lg:text-sm text-center'>{title}</a>
					<svg
						className='fill-current h-4 w-4'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 20 20'
					>
						<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
					</svg>
				</div>
				<ul className='text-center lg:text-start lg:hidden lg:absolute group-hover:flex lg:bg-slate-100 flex-col gap-4 lg:px-2 lg:py-4 rounded-lg'>
					{children}
				</ul>
			</div>
		</>
	);
};

export default Dropdown;
