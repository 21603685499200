import React from 'react';

interface GalleryItem2Props {
    children?: string | JSX.Element | JSX.Element[]
}

export const GalleryItem2: React.FC<GalleryItem2Props> = props => {
    return (
        <>
            <div className={'w-full h-96 bg-red-100 flex rounded-lg overflow-hidden'}>
                {props.children}
            </div>
        </>
    );
};