import React from 'react';
import {ImagePreloader} from "../images/imagePreloader";
import logoImage from './../../assets/logo.png'

interface LogoProps {

}

export const Logo: React.FC<LogoProps> = props => {
    return (
        <>
            <div className="h-20 lg:h-24">
                <ImagePreloader image={logoImage}/>
            </div>
        </>
    );
};