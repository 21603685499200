import React from 'react';
import Lottie from "lottie-react";
import {FeatureIconType1} from "../../../shared/icons/feature-icon-type1";
import {ImagePreloader} from "../../../shared/images/imagePreloader";
import {ComponentLayout} from "../../../shared/section-layout/UI/component-layout";
import {SimpleTitle, titleAlign} from "../../../shared/titles/simpleTitle";
import { FeaturesProps } from '../interface/unterface';

import celebrationAnimation from "../../../assets/lottie/133137-party-hats.json"



export const FeaturesType1: React.FC<FeaturesProps> = (props) => {
    return (
        <>
            <ComponentLayout addClass={'relative'}>
                <div className='hidden lg:block absolute top-0 left-32 w-96'>
                    <Lottie animationData={celebrationAnimation} />
                </div>
                <SimpleTitle
                    title={'Праздник, который дети запомнят навсегда'}
                    description={'Мы тщательно отбирали всех членов нашей команды и тренировали их, чтобы развлекать ваших детей 🎈'}
                    titleAlign={titleAlign.end}
                />
                <div className={'flex lg:flex-row flex-col w-full gap-12'}>
                    <div className="lg:w-1/3 flex shrink h-full">
                        <div className="overflow-hidden">
                            <ImagePreloader image={props.data.mainImage}/>
                        </div>
                    </div>
                    <div className={(props.align === 'left' ? ' -order-1 ' : '') + " lg:w-2/3 flex"}>
                        <ul className={'grid grid-cols-1 lg:grid-cols-2 gap-6 p-8'}>
                            {props?.data?.content?.map((el, index) => {
                                return (
                                    <li key={index} className={'flex flex-col mx-auto gap-2 relative m-auto'}>
                                        <FeatureIconType1 iconSource={el.iconSource} iconColor={el.iconColor}/>
                                        <h4 className={'font-bold text-xl'}>{el.title}</h4>
                                        <p>{el.description}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </ComponentLayout>
        </>
    );
};