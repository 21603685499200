import React, {useState} from 'react';
import {WwwIcon} from "../../../shared/icons/WWW-icon";
import {ComponentLayout} from "../../../shared/section-layout/UI/component-layout";
import {SimpleTitle, titleAlign} from "../../../shared/titles/simpleTitle";
import {wwwImages} from "../data/data";
import { iWWWItem, WhereWeWorkProps } from '../types/types';





const list: iWWWItem[] = [
    {title: 'В КАФЕ/РЕСТОРАНЕ', icon: wwwImages.kafe},
    {title: 'В ПАРКЕ', icon: wwwImages.park},
    {title: 'В ДЕТСКОМ САДУ/ШКОЛЕ', icon: wwwImages.det_sad},
    {title: 'В ЧАСТНОМ ДОМЕ/НА ДАЧЕ', icon: wwwImages.dom},
    {title: 'В КВАРТИРЕ', icon: wwwImages.kvartira},
    {title: 'В ДЕТСКОМ ЦЕНТРЕ', icon: wwwImages.det_center},
]

export const WhereWeWork: React.FC<WhereWeWorkProps> = props => {
    const [listItem, setListItem] = useState(list);
    return (
        <>
            <ComponentLayout>
                <SimpleTitle
                    title={' '}
                    description={''}
                    titleAlign={titleAlign.center}
                />
                <div className={'w-full py-8 lg:py-16 lg:px-24 rounded-[50px] bg-blue-200 flex flex-col gap-16 justify-center items-center'}>
                    <h3 className={'text-3xl text-center lg:w-full w-2/3'}>Где мы работаем</h3>

                    <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 lg:gap-y-8">
                        {listItem.map((el, index) => {
                            return <WwwIcon key={index} title={el.title} image={el.icon}/>
                        })}
                    </ul>

                </div>
            </ComponentLayout>
        </>
    );
};