import React from 'react';
import {LandingStructure} from "../../../app/API/landing-structure";
import {ComponentContext} from "../../../processes/context";

interface MainPageProps {
    children?: string | JSX.Element | JSX.Element[]
}


export const MainPage: React.FC<MainPageProps> = () => {
    return (
        <>
            <main className={'flex w-full h-full flex-col gap-y-12'}>
                {LandingStructure.map(({component, anchor}, index) => {
                    return (
                        <ComponentContext.Provider key={index} value={{anchor}}>
                            {component}
                        </ComponentContext.Provider>
                    )
                })}
            </main>
        </>
    );
};